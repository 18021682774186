/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao, TituloExplicacaoSemUnderline } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function DespesaPessoal(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                        <option key='2012' value='2012'>
                            2012
                        </option>
                        <option key='2011' value='2011'>
                            2011
                        </option>
                        <option key='2010' value='2010'>
                            2010
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2016.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2016.pdf")}><span>Demonstrativo da Despesa com Pessoal - set 2015 a ago 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_01_04_2016.pdf")}><span>Demonstrativo da Despesa com Pessoal - Mai 2015 a Abr 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2015.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_24_09_2015.pdf")}><span>Demonstrativo da Despesa com Pessoal - Set 2014 a Ago 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_01_04_2015.pdf")}><span>Demonstrativo da Despesa com Pessoal - Mai 2014 a Abr 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2014.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2014.pdf")}><span>Demonstrativo da Despesa com Pessoal - Set/13 a Ago/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_30_04_2014.pdf")}><span>Demonstrativo da Despesa com Pessoal - mai/13 a abr/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2013.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2013.pdf")}><span>Demonstrativo da Despesa com Pessoal - Set/12 a Ago/2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_30_04_2013.pdf")}><span>Demonstrativo da Despesa com Pessoal - mai/12 a abr/2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2012' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2012.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2012.pdf")}><span>Demonstrativo da Despesa com Pessoal - Set/11 a Ago/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_30_04_2012.pdf")}><span>Demonstrativo da Despesa com Pessoal - mai/11 a abr/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2011' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2011.pdf")}><span>Demonstrativo da Despesa com Pessoal - Set/10 a Ago/2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_30_04_2011.pdf")}><span>Demonstrativo da Despesa com Pessoal - mai/10 a abr/2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2010' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_12_2010.pdf")}><span>Demonstrativo da Despesa com Pessoal - mai/09 a abr/2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP1_CMC_Contabilidade_31_12_2010.pdf")}><span>Demonstrativo da Despesa com Pessoal - Jan a Dez 2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DDP_CMC_Contabilidade_31_08_2010.pdf")}><span>Demonstrativo da Despesa com Pessoal - set/09 a ago/2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <br />
                <TituloExplicacaoSemUnderline>
                NOTA: O "DEMONSTRATIVO DA DESPESA COM PESSOAL" é publicado quadrimestralmente (abril, agosto e dezembro de cada ano), considerando o período móvel (últimos 12 meses).
                </TituloExplicacaoSemUnderline>
                <UltimaAtualizacao>
                Última Atualização em 22/02/2019 11:11
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default DespesaPessoal;
