import { lighten } from 'polished';

export interface ITheme {
    primary?: any;
    primaryProcesso?: any;
    error?: any;
    inputBorder?: any;
    backGround?: any;
    hover?: any;
    gradient?: any;
    text?: any;
    label?: any;
    backgroundBranco?: any;
    textoPreto?: any;
    inputBackground?: any;
    inputBackgroundDisabled?: any;
}

const defaultTheme: ITheme = {
    primary: '#20733F',
    primaryProcesso: '#798ac9',
    error: '#d44059',
    inputBorder: lighten(0.1, '#adb5bd'),
    backGround: lighten(0.1, '#b8f3f5'),
    hover: lighten(0.1, '#84ca9a'),
    gradient: 'linear-gradient(to top right,#20733F, #84ca9a)',
    text: '#fff',
    label: '#ccc',
    backgroundBranco: '#fff',
    textoPreto: '#000',
    inputBackground: '#f8f9fa',
    inputBackgroundDisabled: '#dcdcdc',
};

export default defaultTheme;
