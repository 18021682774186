/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { BotaoComoLink, Container, ItemLista, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function OrcamentoLegislativo(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Orcamento_do_Legislativo_-_2016_-_Atualizado.pdf")}><span>Orçamento do Legislativo - 2016 - Atualizado</span></BotaoComoLink><FaFilePdf /></ItemLista>
                <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Orcamento_do_Legislativo_-_2016.pdf")}><span>Orçamento do Legislativo - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Orcamento_do_Legislativo_2015.pdf")}><span>Orçamento do Legislativo - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                <UltimaAtualizacao>
                Última Atualização em 12/02/2019 10:49
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default OrcamentoLegislativo;
