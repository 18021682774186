import axios from '../configs/axiosConfig';

    function downloadAnexo(e: any, arqNome: string): any {
        e.preventDefault();
        axios({
            method: 'GET',
            url: `/download/${arqNome}`,
            headers: {
                Accept: 'application/pdf',
            },
            responseType: 'blob',
        })
            .then((res) => {
                const blob = new Blob([res.data], { type: res.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = res.headers['content-disposition'];
                let fileName = arqNome;
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) {
                        fileName = fileNameMatch[1];
                    }
                }
                link.setAttribute('download', fileName);
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setTimeout(function(){
                    // no Firefox é necessário um  delay para dar o revoke
                    window.URL.revokeObjectURL(url);
                }, 100);

            })
            .catch(() => {
                alert('Arquivo não disponível.');
                console.log('Erro ao efetuar o download do anexo.');
            });
    }
export const download = downloadAnexo;
