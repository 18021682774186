import React from 'react';

import Select from '../SelectBaseAno';

export interface ISelectAnoContrato {
    name: string;
    label?: string;
    changeHandler?: any;
    obrigatorio?: any;
    onChange?: any;
    style?: any;
    rest?: any;
    defaultValue?: any;
}

const SelectAnoContrato: React.FC<ISelectAnoContrato> = ({ name, changeHandler, label, ...rest }) => {
    const options = [
        {
            label: '2016',
            value: '2016',
        },
        {
            label: '2015',
            value: '2015',
        },
        {
            label: '2014',
            value: '2014',
        },
        {
            label: '2013',
            value: '2013',
        },
        {
            label: '2012',
            value: '2012',
        },
        {
            label: '2011',
            value: '2011',
        },
        {
            label: '2010',
            value: '2010',
        },
    ];

    return (
        <Select name={name} label={label} options={options} onChange={changeHandler} {...rest} />
    );
};

export default SelectAnoContrato;
