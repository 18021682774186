/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, LinkExterno, Texto, TituloExplicacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';

function LrfResumido(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloExplicacao>Nota:</TituloExplicacao>
                <Texto>Estes relatórios são consolidados para todos os poderes e entidades do município.</Texto>
                <Texto>No link abaixo você será direcionado ao portal da transparência da Prefeitura Municipal de Curitiba.</Texto>
                <LinkExterno href="https://www.curitiba.pr.gov.br/contaspublicas/" target="_blank" rel="noopener noreferrer"><span>LRF - Relatório Resumido da Execução Orçamentária.</span></LinkExterno>
                <br />
                <br />
                <Texto>Lei de Responsabilidade Fiscal:<LinkExterno href="http://www.planalto.gov.br/ccivil_03/leis/LCP/Lcp101.htm" target="_blank" rel="noopener noreferrer"><span>LEI COMPLEMENTAR Nº 101, DE 4 DE MAIO DE 2000</span></LinkExterno></Texto>
                <br />
            </Container>
        </DefaultLayout>
    );
}

export default LrfResumido;
