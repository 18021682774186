import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *:focus {
        outline: 0;
    }

    body {
        -webkit-font-smoothing: antialiased !important;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    body, input, button, select, span {
        font-family: Roboto, Arial, Helvetica, sans-serif;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }


`;
