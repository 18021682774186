import styled from 'styled-components';

export const Container = styled.div`
    footer {
        text-align: center;
        width: 100%;
        hr {
            border: 1.5px solid #000;
        }

        label {
            font-size: 12px;
            font-weight: 700;
        }
    }
`;
