/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, LinkExterno, Texto, TituloExplicacaoSemUnderline, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { Link } from 'react-router-dom';

function Fec(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Texto>O Fundo Especial da Câmara Municipal de Curitiba (FEC) foi criado com o objetivo de acumular recursos para a construção da nova sede do Poder Legislativo Municipal.</Texto>
                <Texto>Os recursos depositados no Fundo são provenientes da economia orçamentária da Câmara a partir do exercício de 2009 e só serão utilizados após a aprovação de projeto e realização de processo licitatório específico para a obra pretendida.</Texto>
                <Texto>O Fundo Especial da Câmara Municipal de Curitiba não possui contabilidade própria por se tratar de fundo especificamente financeiro.</Texto>
                <Texto>Legislação: </Texto>
                <LinkExterno href="https://www.leismunicipais.com.br/a/pr/c/curitiba/lei-ordinaria/2009/1309/13087/lei-ordinaria-n-13087-2009-institui-o-fundo-especial-da-camara-municipal-de-curitiba-fec?q=13087" target="_blank" rel="noopener noreferrer"><span>Lei 13.087 de 2009 que INSTITUI O FUNDO ESPECIAL DA CÂMARA MUNICIPAL DE CURITIBA - FEC</span></LinkExterno>
                <br />
                <LinkExterno href="https://www.leismunicipais.com.br/a/pr/c/curitiba/lei-ordinaria/2013/1436/14358/lei-ordinaria-n-14358-2013-altera-a-lei-n-13087-de-16-de-janeiro-de-2009-que-institui-o-fundo-especial-da-camara-municipal-de-curitiba-fec?q=14358" target="_blank" rel="noopener noreferrer"><span>Lei 14.358 de 2013 que ALTERA A LEI Nº 13.087, DE 16 DE JANEIRO DE 2009, QUE "INSTITUI O FUNDO ESPECIAL DA CÂMARA MUNICIPAL DE CURITIBA - FEC". </span></LinkExterno>
                <br />
                <br />
                <Texto>A movimentação completa das contas bancárias do FEC pode ser acessada através dos <Link to="/diario-bancos">Diários de Bancos.</Link></Texto>
                <TituloExplicacaoSemUnderline>Fica extinto o Fundo Especial da Câmara Municipal de Curitiba - FEC, conforme <a href="https://leismunicipais.com.br/a/pr/c/curitiba/lei-ordinaria/2016/1487/14870/lei-ordinaria-n-14870-2016-extingue-o-fundo-especial-da-camara-municipal-de-curitiba-fec-revoga-a-lei-n-13087-de-16-de-janeiro-de-2009-e-da-outras-providencias" target="_blank" rel="noopener noreferrer"><span>Lei nº 14870</span></a>, promulgada em de 20/06/2016 e publicada no Diário Oficial do Município nº116 de 22/06/2016, cujo saldo foi repassado ao Tesouro Municipal, conforme legislação vigente, em 27/06/2016.</TituloExplicacaoSemUnderline>
                <UltimaAtualizacao>
                Última Atualização em 06/07/2016 10:30.
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default Fec;
