import styled from 'styled-components';

export const Container = styled.div`
    margin: 0;
    padding: 0;
    width: 100% !important;
`;

export const BannerTituloInterno = styled.div`
    color: #000;
    font-size: 46px;
    margin-left:5px;
    font-weight: 700;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    width: 400px;
`;

export const Linha = styled.hr`
    border: 1.5px solid #000;
`;

export const MenuInterno = styled.div`
    display: flex;
    flex-direction: row;
    grid-template-columns: 100px 100px 100px 100px 100px 100px;
`;

export const ItemMenu = styled.div`
    margin-top: 15px;
    margin-left: 15px;
    text-align: center;
`;

export const BotaoTituloInterno = styled.button`
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    width: 155px;
    height: 60px;
    border: none;
    border-radius: 8px;
    color: #fff;
    background: #20733F;
    padding: 7px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        background:  #84ca9a ;
    }
`;



