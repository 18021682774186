import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

import Button from '../Button';

interface IFechar {
    name?: string;
    clickHandler?: any;
}

const Fechar: React.FC<IFechar> = ({ name, clickHandler }) => {
    return (
        <Button id={name} name={name} onClick={clickHandler}>
            <FaRegWindowClose />
            Fechar
        </Button>
    );
};

export default Fechar;
