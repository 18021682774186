import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${({ theme }) => theme.primary};
    border: 0;
    border-radius: 4px;
    height: 36px;
    padding: 0 15px;
    color: ${({ theme }) => theme.text};
    font-size: 14px;
    transition: background 0.2s;

    &:hover {
        background: ${({ theme }) => theme.hover};
    }

    &:disabled {
        background: #dcdcdc;
        cursor: auto;
    }

    svg {
        margin-right: 10px;
        margin-left: 5px;
    }
`;
