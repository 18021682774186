import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Container, BasicSelect } from './styles';

export interface ISelectBaseAno {
    id?: any;
    name: string;
    label?: string;
    options?: any;
    size?: number;
    rest?: any;
    onChange?: any;
    disabled?: any;
}

const SelectBaseAno: React.FC<ISelectBaseAno> = ({
    id,
    name,
    label,
    options,
    size,
    ...rest
}) => {
    const selectRef = useRef(null);
    const { fieldName, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    const montaOptions: any = () => {
        return (
            <>
                {options.map((opt: any) => {
                    return (
                        <option key={opt.value} value={opt.value}>
                            {opt.label}
                        </option>
                    );
                })}
            </>
        );
    };

    return (
        <Container size={size}>
            {label && (
                <label htmlFor={fieldName}>
                    {label}
                </label>
            )}

            <BasicSelect
                name={fieldName}
                id={fieldName}
                ref={selectRef}
                defaultValue={'-1'}
                {...rest}>
                {montaOptions()}
            </BasicSelect>

            {error && <span className="error">{error}</span>}
        </Container>
    );
};

export default SelectBaseAno;
