import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
    margin-left: 15px;
    background-color: #fff;
    color: #444;
    width: 100%
`;

export const Titulo = styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const Texto = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;

    a {
        text-decoration: underline;
    }
`;

export const UltimaAtualizacao = styled.p`
    text-align: justify;
    font-size: 14px;
    font-weight: bold;
    color: #2F4F4F;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;


