/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Wrapper, Titulo, Lupa, Botoes, BotaoHome } from './styles';
import DefaultLayout from '../_layouts/default';
import ImgLupa from '../../assets/lupa.png';
import { Link } from 'react-router-dom';

function Home(): React.ReactNode {

    return (
        <DefaultLayout>
            <Container>
                <Wrapper>
                    <Titulo>PORTAL DA TRANSPARÊNCIA</Titulo>
                    <Lupa><img src={ImgLupa} alt="Lupa"/></Lupa>
                    <Botoes>
                        <table>
                            <tbody>
                                <tr>
                                    <td><Link to="/receitas-e-despesas"><BotaoHome>RECEITAS E DESPESAS</BotaoHome></Link></td>
                                    <td><Link to="/cargos-e-salarios"><BotaoHome>CARGOS E SALÁRIOS</BotaoHome></Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/portarias-e-atos"><BotaoHome>PORTARIAS E ATOS</BotaoHome></Link></td>
                                    <td><Link to="/licitacoes-e-contratos"><BotaoHome>LICITAÇÕES E CONTRATOS</BotaoHome></Link></td>
                                </tr>
                                <tr>
                                    <td><Link to="/orcamento-e-financas"><BotaoHome>ORÇAMENTO E FINANÇAS</BotaoHome></Link></td>
                                    <td><Link to="/patrimonio"><BotaoHome>PATRIMÔNIO</BotaoHome></Link></td>
                                </tr>
                             </tbody>
                        </table>
                    </Botoes>
                </Wrapper>
            </Container>
        </DefaultLayout>
    );
}

export default Home;
