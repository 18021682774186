import styled from 'styled-components';

export const ContainerModal = styled.div`
    width: 800px;
    p {
        text-align: center;
        margin-bottom: 15px;
    }
    h1 {
        font-size: 16px;
        color: #000;
        text-align: center;
        margin-bottom: 10px;
    }

    hr {
        color: #000;
    }

    table {
        border-collapse: collapse;
        tbody {
            tr {
                td {
                    padding: 5px;
                    border: 1px solid #000;
                }
            }
        }
    }
`;

export const UltimaAtualizacao = styled.p`
    text-align: justify;
    font-size: 14px;
    font-weight: bold;
    color: #2F4F4F;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const Descricao = styled.span`
    font-weight: 700;
`;

export const Scroll = styled.div`
    overflow-y: auto;
    height:700px;
`;

export const CentralizaBotao = styled.p`
    display: grid;
    justify-items: center;
    margin: auto;
`;

export const TabelaEscolha = styled.table`
    border: 1px solid #000;
    border-collapse: collapse;
    thead {

        tr {
            td {
                padding-top: 5px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
`;

export const TabelaResultado = styled.table`
    border-collapse: collapse;
    thead {
            tr {
                background: #F8F9FA;
                td {
                    border: 1px solid #000;
                    font-weight: 700;
                    padding: 5px;
                    margin: 0px;
                }
            }
        }
        tbody {
            border: 1px solid #000;
            tr {
                td {
                    padding: 5px;
                    border: 1px solid #000;
                }
            }
        }
`;

export const BotaoComoLink = styled.button`
    background: transparent;
    text-align: justify;
    font-weight: normal;
    border: none;
    color: #000;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;

    span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
    }

    &:hover {
        text-decoration: none;
    }
`;

export const TabelaAditivo = styled.table`
    border: 0 !important;
    width: 100%;
    tbody {
            tr {
                td {
                    padding: 5px;
                }
            }
        }
`;
