import styled from 'styled-components';
import img from '../../assets/seta-combo.jpg';

export const Container = styled.div`
    margin-top: 30px;
    margin-left: 15px;
    background-color: #fff;
    color: #444;
    width: 100%
`;

export const Titulo = styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const Texto = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;

    a {
        text-decoration: underline;
    }
`;

export const TituloExplicacao = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const TituloAno = styled.p`
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-top: 30px;
    margin-left: 20px;
`;

export const CabecalhoLista = styled.ul`
    text-align: left;
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 30px;
`;

export const ItemLista = styled.li`
    margin-left: 40px;
    margin-bottom: 10px;
    color: #000;

    span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: 700;
        }

    a {
        text-decoration: none;
        color: #000;
        span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const ListaInterna = styled.li`
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 35px;
    font-size: 16px;
    font-weight: normal;
    color: #000;

    a {
        text-decoration: none;
        color: #000;
        span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
        }

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const UltimaAtualizacao = styled.p`
    text-align: justify;
    font-size: 14px;
    font-weight: bold;
    color: #2F4F4F;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const BotaoComoLink = styled.button`
    background: transparent;
    text-align: justify;
    font-weight: normal;
    border: none;
    color: #000;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;

    span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
    }

    &:hover {
        text-decoration: underline;
    }
`;

export const SelectAno = styled.select`
    display: flex;
    appearance: none;
    background: url(${img}) no-repeat #f8f9fa right;
    height: 28px;
    min-width: 100px;
    border: 1px solid;
    border-radius: 2px;
    padding: 0 5px;
    color: ${({ theme }) => theme.textoPreto};

    option {
        display: flex;
        min-height: 36px;
        height: 36px;
        color: #000;
    }
`;



