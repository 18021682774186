/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Texto, Titulo, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';

function Glossario(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Titulo>Glossário</Titulo>
                <Texto>
                <b>O glossário reúne as principais nomenclaturas e expressões usadas nos documentos, pareceres e registros elaborados pela Câmara Municipal.
                O objetivo é facilitar o entendimento de todos os aspectos da atividade parlamentar e da administração deste poder público.
                Para localizar rapidamente um termo técnico ou uma expressão relacionada ao trabalho legislativo, utilize a função CTRL+F.</b>
                </Texto>
                <Texto>
                <b>Bens de Consumo:</b> Os que atendem diretamente à demanda a médio ou longo prazo ex: papel, canetas, água.
                </Texto>
                <Texto>
                <b>Bens Permanentes:</b> Bens permanentes consistem nos bens móveis de uma organização que, em razão de seu uso corrente, não perdem sua identidade física ou têm uma durabilidade superior a 2 anos. Na gestão patrimonial, são considerados como bens permanentes artefatos do tipo: móveis em geral, computadores, veículos, dentre outros.
                </Texto>
                <Texto>
                <b>Bens Públicos:</b> São todos os bens móveis ou imóveis pertencentes à União, Estados, Distrito Federal, Municípios e suas respectivas autarquias e associações públicas.
                </Texto>
                <Texto>
                <b>Cessão:</b> É a transferência gratuita da posse de um bem público de uma entidade ou órgão para outro, a fim de que o cessionário o utilize nas condições estabelecidas no respectivo termo, por tempo certo ou determinado.
                </Texto>
                <Texto>
                <b>Classificação Orçamentária da Despesa:</b> Organização do orçamento segundo critérios que possibilitem a compreensão geral das funções do orçamento público, propiciando informações para a administração, a gerência e a tomada de decisões. As informações apresentam a classificação para a despesa nos seguintes níveis: classificação institucional, classificação por funções, subfunções e programas, classificação por projetos e atividades e classificação por natureza da despesa. A classificação da despesa orçamentária é determinada pela Lei Federal nº 4.320, de 1964 e por Portarias Interministerial da Secretaria do Tesouro Nacional - STN, do Ministério da Fazenda e da Secretaria de Orçamento Federal - SOF, do Ministério do Planejamento, Orçamento e Gestão.
                </Texto>
                <Texto>
                <b>CNPJ/CPF:</b> Corresponde ao número do Cadastro Nacional da Pessoa Jurídica e/ou do Cadastro da Pessoa Física, conforme determinação da Receita Federal do Brasil.
                </Texto>
                <Texto>
                <b>Credor:</b> É a pessoa física ou jurídica contratada pela Administração Pública, para o fornecimento de bens ou serviços e/ou a realização de obras. Há casos em que o credor é a folha de pagamento, quando se referir ao pagamento de despesas de pessoal.
                </Texto>
                <Texto>
                <b>Descrição do Bem Fornecido / Serviço Prestado / Objeto da Despesa:</b> Contém a discriminação com histórico das aquisições de bens e serviços, fornecimentos e realização de obras, efetuadas pela Administração Pública, para efetivação dos seus objetivos.
                </Texto>
                <Texto>
                <b>Dispensa / Inexigibilidade:</b> São as hipóteses de aquisições de bens e serviços pela Administração Pública com base no art. 24 e seus incisos, exceto I e II, art. 25, incisos I a II, §§ 1º e 2º, combinado com art. 13, observados na sua implementação as condições do art. 26, da Lei nº 8.666, de 1993 - Estatuto das Licitações e Contratos.
                </Texto>
                <Texto>
                <b>Dispensada por Limite:</b> São as hipóteses de aquisições de bens e serviços pela Administração Pública com base nos incisos I e II, do art. 24, da Lei nº 8.666, de 1993 - Estatuto das Licitações e Contratos.
                </Texto>
                <Texto>
                <b>Doação:</b> Contrato pelo qual uma pessoa, por liberalidade, transfere um bem do seu patrimônio para o de outra. É contrato civil e não administrativo, fundado na liberalidade do doador, embora possa ser com encargos para o donatário.
                </Texto>
                <Texto>
                <b>Modalidade de Licitação:</b> São as diversas modalidades de licitações contidas na legislação que regem as aquisições de bens e serviços e alienação de bens pela Administração Pública (Concorrência, Tomada de Preços, Convite, Concurso, Leilão, previstas no art. 22 da Lei nº 8.666, de 1993 - Estatuto das Licitações e Contratos e Pregão, prevista na Lei nº 10.520, de 2002 - Modalidade de Licitação - Pregão).
                </Texto>
                <Texto>
                <b>Nº e Data do Empenho:</b> Empenho de despesa é o ato emanado de autoridade competente que cria para o Estado obrigação de pagamento pendente ou não de implemento de condição (art. 58, da Lei Federal nº 4.320, de 1964). Para cada empenho será extraído um documento denominado "nota de empenho" que indicará o nome do credor, a representação e a importância da despesa bem como a dedução desta do saldo da dotação própria. O empenho constitui instrumento de programação, sua utilização fornece o panorama dos compromissos assumidos e das dotações ainda disponíveis. Isto constitui uma garantia para os fornecedores, prestadores de serviços, aos contratantes em geral. Os empenhos podem ser feitos por: estimativa (empenho da despesa cujo montante não se pode determinar), ordinários (empenho da despesa cujo montante é certo e determinado), e globais (empenho da despesa contratuais e outras sujeitas a parcelamento). Os empenhos são numerados em ordem sequencial anual. O empenho consiste em um dos estágios da despesa pública.
                </Texto>
                <Texto>
                <b>Permuta:</b> É o contrato pelo qual as partes transferem e recebem um bem, uma da outra, bens esses que se substituem reciprocamente no patrimônio dos permutantes. A permuta pressupõe igualdade de valor entre os bens permutáveis.
                </Texto>
                <Texto>
                <b>Processo Administrativo:</b> Sucessão encadeada de atos, juridicamente ordenados, destinados todos à obtenção de um resultado final, que consubstancia uma determinada decisão administrativa com o fim de expressar a manifesta vontade da Administração em determinada matéria, neste caso visando a realização da despesa pública.
                </Texto>
                <Texto>
                <b>Valor Anulado:</b> Corresponde ao valor do empenho que não sendo utilizado e será anulado. Ocorre, por exemplo, nos empenhos estimativos, onde não se conhece o montante exato da despesa. Podem ser os casos de consertos diversos. A anulação de empenho também pode ocorrer quando o credor (contratado) não realiza o adimplemento de condições, ou seja, não cumpre às condições pactuadas no acordo ou ajuste com a Administração Pública.
                </Texto>
                <Texto>
                <b>Valor Empenhado:</b> Corresponde ao valor orçamentário reservado a determinado credor pelo fornecimento de bens ou serviços e/ou a realização de obras e a realização da despesa com a folha de pagamento.
                </Texto>
                <Texto>
                <b>Valor Liquidado:</b> Corresponde a verificação da origem e o objeto do que se deve pagar, a importância exata a pagar e a quem se deve pagar a importância, para extinguir a obrigação. A liquidação da despesa por fornecimentos feitos ou serviços prestados terá por base: o contrato, ajuste ou acordo respectivo, a conformidade com a nota de empenho e os comprovantes da entrega do material ou da prestação efetiva do serviço. é a verificação do direito do credor ao pagamento, isto é, verificar se o implemento de condição foi cumprido, com base em documentos. Constitui um dos estágios da despesa pública.
                </Texto>
                <Texto>
                <b>Valor Pago:</b> Corresponde a último estágio de realização da despesa pública. Quando o valor é deferido em favor do credor. É realizado através de ordem de pagamento. O pagamento é exarado no processo da despesa pela autoridade pessoa legalmente investida como ordenadora de pagamentos, o titular da Presidência, no caso da Câmara Municipal de Curitiba.
                </Texto>
                <UltimaAtualizacao>
                Última Atualização em 21/08/2015 11:00
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default Glossario;
