import React from 'react';
import { Container, Linha, BannerTituloInterno, MenuInterno, ItemMenu, BotaoTituloInterno } from './styles';

const TituloInterno: React.FC = () => {
    return (
        <Container>
            <BannerTituloInterno>PORTAL DA TRANSPARÊNCIA</BannerTituloInterno>
            <Linha />
            <MenuInterno>
                <ItemMenu><a href="/receitas-e-despesas"><BotaoTituloInterno>RECEITAS E DESPESAS</BotaoTituloInterno></a></ItemMenu>
                <ItemMenu><a href="/cargos-e-salarios"><BotaoTituloInterno>CARGOS E SALÁRIOS</BotaoTituloInterno></a></ItemMenu>
                <ItemMenu><a href="/portarias-e-atos"><BotaoTituloInterno>PORTARIAS E ATOS</BotaoTituloInterno></a></ItemMenu>
                <ItemMenu><a href="/licitacoes-e-contratos"><BotaoTituloInterno>LICITAÇÕES E CONTRATOS</BotaoTituloInterno></a></ItemMenu>
                <ItemMenu><a href="/orcamento-e-financas"><BotaoTituloInterno>ORÇAMENTO E FINANÇAS</BotaoTituloInterno></a></ItemMenu>
                <ItemMenu><a href="/patrimonio"><BotaoTituloInterno>PATRIMÔNIO</BotaoTituloInterno></a></ItemMenu>
           </MenuInterno>
        </Container>

    );
};

export default React.memo(TituloInterno);
