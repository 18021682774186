/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { BotaoComoLink, Container, ItemLista, Texto, TituloExplicacao, TituloLista, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function CargosESalarios(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloExplicacao>
                    Sem verba de representação
                </TituloExplicacao>
                <Texto>
                    Não há pagamento de verbas de representação ou verbas de gabinete.
                </Texto>
                <TituloExplicacao>
                    Sem valores reembolsáveis
                </TituloExplicacao>
                <Texto>
                    Não há pagamento de valores reembolsáveis de qualquer natureza (notas fiscais, cópias de depósitos, transferências ou cheques utilizados no reembolso, discriminados pelo nome, cargo e lotação do agente).
                </Texto>
                <TituloExplicacao>
                    Sem cartão corporativo
                </TituloExplicacao>
                <Texto>
                    Não há sistema de liberação de cartões corporativos.
                </Texto>
                <UltimaAtualizacao>
                Última Atualização em 31/03/2016 10:30
                </UltimaAtualizacao>
                <TituloLista><FaAngleRight size={28}/>Tabela de funções gratificadas</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Remuneracao_das_Funcoes_Gratificadas_a_partir_de_04_2016.pdf")}><span>Servidores Efetivos: Remuneração das Funções Gratificadas a partir de 04/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Remuneracao_das_Funcoes_Gratificadas_a_partir_de_04_2016.csv")}><span>Servidores Efetivos: Remuneração das Funções Gratificadas a partir de 04/2016</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos Remuneracao_das_Funcoes_Gratificadas_de_05_2015.pdf")}><span>Servidores Efetivos:Remuneração das Funções Gratificadas de 05/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos Remuneracao_das_Funcoes_Gratificadas_de_05_2015.csv")}><span>Servidores Efetivos:Remuneração das Funções Gratificadas de 05/2015</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos Remuneracao_das_Funcoes_Gratificadas_de_05_2014.pdf")}><span>Servidores Efetivos:Remuneração das Funções Gratificadas de 05/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos Remuneracao_das_Funcoes_Gratificadas_de_05_2014.csv")}><span>Servidores Efetivos:Remuneração das Funções Gratificadas de 05/2014</span></BotaoComoLink><FaFileCsv /></ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 05/12/2018 16:59
                </UltimaAtualizacao>
                <TituloLista><FaAngleRight size={28}/>Tabela salarial de servidores efetivos</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Tabelas_de_Vencimentos_a_partir_de_04_2016.csv")}><span>Servidores Efetivos: Tabelas de Vencimentos a partir de 04/2016</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Tabelas_de_Vencimentos_a_partir_de_04_2015.pdf")}><span>Servidores Efetivos: Tabelas de Vencimentos a partir de 04/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Tabelas_de_Vencimentos_a_partir_de_04_2015.csv")}><span>Servidores Efetivos: Tabelas de Vencimentos a partir de 04/2015</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Tabelas_de_Vencimentos_a_partir_de_04_2014.pdf")}><span>Servidores Efetivos: Tabelas de Vencimentos a partir de 04/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Efetivos _Tabelas_de_Vencimentos_a_partir_de_04_2014.csv")}><span>Servidores Efetivos: Tabelas de Vencimentos a partir de 04/2014</span></BotaoComoLink><FaFileCsv /></ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 10/07/2018 09:45
                </UltimaAtualizacao>
                <TituloLista><FaAngleRight size={28}/>Tabela salarial dos servidores comissionados, vereadores e presidente</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao,_Vereadores_e_Presidente_a_partir_de_04_2016.pdf")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão, Vereadores e Presidente a partir de 04/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao,_Vereadores_e_Presidente_a_partir_de_04_2016.csv")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão, Vereadores e Presidente a partir de 04/2016</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao,_Vereadores_e_Presidente_a_partir_de_01_2016.pdf")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão, Vereadores e Presidente a partir de 01/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao,_Vereadores_e_Presidente_a_partir_de_01_2016.csv")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão, Vereadores e Presidente a partir de 01/2016</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao_a_partir_de_04_2015.pdf")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão a partir de 04/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao_a_partir_de_04_2015.csv")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão a partir de 04/2015</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados _Remuneracao_dos_Cargos_em_Comissao,Vereadores_e_Presidente_a_partir_de_01_2015.csv")}><span>Servidores Comissionados: Remuneração dos Cargos em Comissão,Vereadores e Presidente a partir de 01/2015</span></BotaoComoLink><FaFileCsv /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Servidores_Comissionados,Vereadores_e_Presidente _Remuneracao_dos_Cargos_em_Comissao_a_partir_de_01_2015.pdf")}><span>Servidores Comissionados,Vereadores e Presidente: Remuneração dos Cargos em Comissão a partir de 01/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 05/12/2018 17:04
                </UltimaAtualizacao>
                <TituloLista><FaAngleRight size={28}/>Concurso público</TituloLista>
                <ul>
                    <ItemLista><a href="https://www.curitiba.pr.leg.br/institucional/concurso-publico/" target="_blank" rel="noopener noreferrer"><span>Publicação dos Editais dos Concursos da Câmara Municipal de Curitiba</span></a></ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 08/08/2015 09:22
                </UltimaAtualizacao>

            </Container>
        </DefaultLayout>
    );
}

export default CargosESalarios;
