import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
    margin-left: 15px;
    background-color: #fff;
    color: #444;
    width: 98%;

`;

export const TabelaEscolha = styled.table`
    border: 1px solid #000;
    border-collapse: collapse;
    thead {

        tr {
            td {
                padding-top: 5px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
`;

export const TabelaResultado = styled.table`
    border-collapse: collapse;
    thead {
            tr {
                background: #F8F9FA;
                td {
                    border: 1px solid #000;
                    font-weight: 700;
                    padding: 5px;
                    margin: 0px;
                }
            }
        }
        tbody {
            border: 1px solid #000;
            tr {
                td {
                    padding: 5px;
                    border: 1px solid #000;
                }
            }
        }
`;



export const Titulo = styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const Texto = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;

    a {
        text-decoration: underline;
    }
`;

export const TituloExplicacao = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const TituloExplicacaoSemUnderline = styled.p`
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const TituloAno = styled.p`
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-top: 30px;
    margin-left: 20px;
`;

export const CabecalhoLista = styled.ul`
    text-align: left;
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 30px;
`;

export const ItemLista = styled.li`
    margin-left: 40px;
    margin-bottom: 10px;
    color: #000;

    span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: 700;
        }

    a {
        text-decoration: none;
        color: #000;
        span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

export const ListaInterna = styled.li`
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 35px;
    font-size: 16px;
    font-weight: normal;
    color: #000;

    a {
        text-decoration: none;
        color: #000;
        span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
        }

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const UltimaAtualizacao = styled.p`
    text-align: justify;
    font-size: 14px;
    font-weight: bold;
    color: #2F4F4F;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
`;

export const BotaoComoLink = styled.button`
    background: transparent;
    text-align: justify;
    font-weight: 700;
    border: none;
    color: #000;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;

    span {
            margin-right: 5px;
            font-size: 16px;
            font-weight: normal;
    }

    &:hover {
        text-decoration: none;
    }
`;

export const SelectAno = styled.select`
    text-align: left;
    font-size: 16px;
`;

export const ContainerEscolha = styled.div`
    margin: auto;
    width: 22%;
`;

export const ContainerAno = styled.div`
    margin: auto;
    width: 15%;
    div {
        margin: auto;
        width: 70%;
    }
`;

export const CentralizaBotao = styled.div`
    align-items: center;
`;



