import styled from 'styled-components';
import img from '../../../assets/seta-combo.jpg';

interface IContainer {
    size?: any;
}

export const Container = styled.div<IContainer>`
    display: flex;
    flex-direction: column;
    flex: ${(props) => props.size};

    span.error {
        font-size: 14px;
        color: ${({ theme }) => theme.error};
        align-self: flex-start;
        margin: 0 0 10px;
        font-weight: bold;
    }

    label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 3px;
        color: #000;
    }
`;

export const BasicSelect = styled.select`
    display: flex;

    /*-webkit-appearance: none;*/

    appearance: none;
    /*-moz-appearance: none;*/
    background: url(${img}) no-repeat #f8f9fa right;
    height: 28px;
    /* min-width: 150px; */
    border: 1px solid;
    border-radius: 2px;
    padding: 0 5px;
    color: ${({ theme }) => theme.textoPreto};
    margin: 1px 0 10px;

    option {
        display: flex;
        min-height: 36px;
        height: 36px;
        color: #000;
    }

    &:disabled {
        background: ${({ theme }) => theme.inputBackgroundDisabled};
    }
`;

export const ContainerObrigatorio = styled.span`
    font-size: 16px;
    color: red !important;
`;
