import React, { useEffect } from 'react';
import Logo from '../../assets/brasao.png';


import {
    Container,
    Menu,
} from './styles';

require('dotenv').config();

const Header: React.FC = () => {

    useEffect(() => {
        //
    }, []);

    return (
        <Container>
            <header>
                <Menu>
                    <a href="/"><img src={Logo} alt="Câmara Municipal de Curitiba"/></a>
                    <div>
                            <ul>
                                <li><a href="/portal">O PORTAL</a></li>
                                <li><a href="/glossario">GLOSSÁRIO</a></li>
                                <li><a href="/legislacao">LEGISLAÇÃO</a></li>
                                <li><a href="/prestacao-contas">PRESTAÇÃO DE CONTAS</a></li>
                                <li><a href="https://www.cmc.pr.gov.br/wspl/system/LogonForm.do" target={"_blank"} rel="noopener noreferrer">LEI DE ACESSO À INFORMAÇÃO</a></li>
                                <li><a href="/organograma">ORGANOGRAMA</a></li>
                                <li><a href="https://www.curitiba.pr.leg.br/transparencia/perguntas-frequentes">DÚVIDAS FREQUENTES</a></li>
                                <li><a href="/contato">CONTATO</a></li>
                            </ul>
                    </div>
                </Menu>
                <hr />
            </header>
        </Container>
    );
    };

export default React.memo(Header);
