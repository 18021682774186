/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, TituloLista } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Patrimonio(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/><Link to="/bens-permanentes">Bens permanentes</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/material-expediente">Materiais de expediente em estoque</Link></TituloLista>
            </Container>
        </DefaultLayout>
    );
}

export default Patrimonio;
