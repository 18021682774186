/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function AtosMesaAte2018(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2018');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2018'} onChange={alteraAno}>
                        <option key='2018' value='2018'>
                            2018
                        </option>
                        <option key='2017' value='2017'>
                            2017
                        </option>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2018' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_da_Mesa_2018__(01_ao_06)pdf.pdf")}><span>Atos da Mesa/2018 (01 ao 06)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2017' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_da_Mesa_nÂO_02_2017_Rpdf.pdf")}><span>Atos da Mesa nº 02/2017 R</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Ato_da_Mesa_nÂO_01_2017pdf.pdf")}><span>Ato da Mesa nº 01/2017</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_nÂO_06_2016pdf.pdf")}><span>ATO DA MESA nº 06/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA__nÂO_05_2016pdf.pdf")}><span>ATO DA MESA nº 05/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA__nÂO_04_2016pdf.pdf")}><span>ATO DA MESA nº 04/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_03_2016pdf.pdf")}><span>ATO DA MESA nº 03/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_nÂO_02_2016pdf.pdf")}><span>ATO DA MESA nº 02/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO__01_2016pdf.pdf")}><span>ATO DA MESA nº 01/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_09_2015pdf.pdf")}><span>ATO DA MESA Nº 09/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_08_2015pdf.pdf")}><span>ATO DA MESA Nº 08/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_07_2015pdf.pdf")}><span>ATO DA MESA Nº 07/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_06_2015pdf.pdf")}><span>ATO DA MESA Nº 06/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_05_2015pdf.pdf")}><span>ATO DA MESA Nº 05/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_04_2015pdf.pdf")}><span>ATO DA MESA Nº 04/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_03_2015pdf.pdf")}><span>ATO DA MESA Nº 03/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_02_2015pdf.pdf")}><span>ATO DA MESA Nº 02/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_01_2015pdf.pdf")}><span>ATO DA MESA Nº 01/2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_04_2014pdf.pdf")}><span>ATO DA MESA Nº 04/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_03_2014pdf.pdf")}><span>ATO DA MESA Nº 03/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_N°_02_2014pdf.pdf")}><span>ATO DA MESA Nº 02/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_N°_01_2014pdf.pdf")}><span>ATO DA MESA Nº 01/2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO_04_2013_pdf.pdf")}><span>ATO DA MESA Nº 04/2013 </span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO__03_2013pdf.pdf")}><span>ATO DA MESA Nº 03/2013 </span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO__02_2013pdf.pdf")}><span>ATO DA MESA Nº 02/2013 </span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "ATO_DA_MESA_NÂO__01_2013pdf.pdf")}><span>ATO DA MESA Nº 01/2013 </span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <UltimaAtualizacao>
                Última Atualização em 08/08/2015 09:22
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default AtosMesaAte2018;
