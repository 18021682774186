/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function AtosAte2018(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2018');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2018'} onChange={alteraAno}>
                        <option key='2018' value='2018'>
                            2018
                        </option>
                        <option key='2017' value='2017'>
                            2017
                        </option>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                        <option key='2012' value='2012'>
                            2012
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2018' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2018_(316_a_352)pdf.pdf")}><span>Atos Outubro/2018 (316 a 352)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2018_(291_a_314)pdf.pdf")}><span>Atos Setembro/2018 (291 a 314)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2018_(257_ao_290)pdf.pdf")}><span>Atos Agosto/2018 (257 ao 290)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2018_(224_ao_256)pdf.pdf")}><span>Atos Julho/2018 (224 ao 256)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2018_(195_ao_223)pdf.pdf")}><span>Atos Junho/2018 (195 ao 223)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2018_(177_ao_194)_pdf.pdf")}><span>Atos Maio/2018 (177 ao 194)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2018_(139_ao_176)pdf.pdf")}><span>Atos Abril/2018 (139 ao 176)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2018_(93_ao_138)pdf.pdf")}><span>Atos Março/2018 (93 ao 138)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro_2018_(44_ao_92)pdf.pdf")}><span>Atos Fevereiro/2018 (44 ao 92)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2018_(01_ao_43)pdf.pdf")}><span>Atos Janeiro/2018 (01 ao 43)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2017' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Dezembro_2017_(572_a_580)pdf.pdf")}><span>Atos Dezembro/2017 (572 a 580)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2017_(545_a_571)pdf.pdf")}><span>Atos Novembro/2017 (545 a 571)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2017_(505_a_544)pdf.pdf")}><span>Atos Outubro/2017 (505 a 544)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2017_(472_a_504)pdf.pdf")}><span>Atos Setembro/2017 (472 a 504)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2017_(417_a_471)pdf.pdf")}><span>Atos Agosto/2017 (417 a 471)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2017_(333_a_416)pdf.pdf")}><span>Atos Julho/2017 (333 a 416)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2017_(310_a_332)pdf.pdf")}><span>Atos Junho/2017 (310 a 332)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2017_(282_a_309_e_235R)pdf.pdf")}><span>Atos Maio/2017 (282 a 309 e 235R)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2017_(207_a_284).pdf")}><span>Atos Abril/2017 (207 a 284)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2017_(160_a_209)pdf.pdf")}><span>Atos Março/2017 (160 a 209)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro__2017_(70_a__159)pdf.pdf")}><span>Atos Fevereiro /2017 (70 a 159)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2017_(01_a_69)pdf.pdf")}><span>Atos Janeiro/2017 (01 a 69)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Dezembro_2016_(442_a_493)_pdf.pdf")}><span>Atos Dezembro/2016 (442 a 493)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2016_(408_a_441)_pdf.pdf")}><span>Atos Novembro/2016 (408 a 441)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2016_(366_a_407)_+_Republica_C_Iespdf.pdf")}><span>Atos Outubro/2016 (366 a 407) + Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2016_(337_a_368)pdf.pdf")}><span>Atos Setembro/2016 (337 a 368)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2016_(288_a_336)pdf.pdf")}><span>Atos Agosto/2016 (288 a 336)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2016_(211_a_287)pdf.pdf")}><span>Atos Julho/2016 (211 a 287)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2016_(172_a_210_Republica_C_Ies)__pdf.pdf")}><span>Atos Junho/2016 (172 a 210 Republicações)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2016_(147_a_171)pdf.pdf")}><span>Atos Maio/2016 (147 a 171)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2016_(95_a_146)pdf.pdf")}><span>Atos Abril/2016 (95 a 146)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2016_(61_a_94)pdf.pdf")}><span>Atos Março/2016 (61 a 94)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro_2016_(33_a_60)pdf.pdf")}><span>Atos Fevereiro/2016 (33 a 60)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2016_(01_a_32_+Republica_C_eo)pdf.pdf")}><span>Atos Janeiro/2016 (01 a 32 +Republicação)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos__Dezembro_2015_(602_a_638)pdf.pdf")}><span>Atos Dezembro/2015 (602 a 638)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2015_(560_a_602)pdf.pdf")}><span>Atos Novembro/2015 (560 a 602)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2015_(530_a_559)pdf.pdf")}><span>Atos Outubro/2015 (530 a 559)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "_Atos_Setembro_2015_(498_a_529)_pdf.pdf")}><span>Atos Setembro/2015 (498 a 529)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2015_(438_a_497)_pdf.pdf")}><span>Atos Agosto/2015 (438 a 497)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2015_(373_a_437)pdf.pdf")}><span>Atos Julho/2015 (373 a 437)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2015_(339_a_372)pdf.pdf")}><span>Atos Junho/2015 (339 a 372)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2015_(300_a_338)_pdf.pdf")}><span>Atos Maio/2015 (300 a 338)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2015_(237_a_299)_pdf.pdf")}><span>Atos Abril/2015 (237 a 299)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2015_(161_a_236)_pdf.pdf")}><span>Atos Março/2015 (161 a 236) </span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro_2015_(69_a_160)pdf.pdf")}><span>Atos Fevereiro/2015 (69 a 160)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2015_(01_a_68)pdf.pdf")}><span>Atos Janeiro/2015 (01 a 68)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Dezembro_2014_(547_a_577)_pdf.pdf")}><span>Atos Dezembro/2014 (547 a 577)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2014_(484_a_546)pdf.pdf")}><span>Atos Novembro/2014 (484 a 546)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2014_(439_a_483)pdf.pdf")}><span>Atos Outubro/2014 (439 a 483)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2014_(410_a_438)pdf.pdf")}><span>Atos Setembro/2014 (410 a 438)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2014_(341_a_409)_+_Republica_C_Iespdf.pdf")}><span>Atos Agosto/2014 (341 a 409) + Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2014_(276_a_340)_pdf.pdf")}><span>Atos Julho/2014 (276 a 340)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2014_(239_a_275)pdf.pdf")}><span>Atos Junho/2014 (239 a 275)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2014_(199_a_238)+Republica_C_Ies_pdf.pdf")}><span>Atos Maio/2014 (199 a 238)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2014_(153_a_198)pdf.pdf")}><span>Atos Abril/2014 (153 a 198)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2014_(110_a_152)+Republica_C_Iespdf.pdf")}><span>Atos Março/2014 (110 a 152)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro_2014_(59_a_109)_pdf.pdf")}><span>Atos Fevereiro/2014 (59 a 109)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2014_(1_a_58)pdf.pdf")}><span>Atos Janeiro/2014 (1 a 58)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Dezembro_2013_(597_a_654)+Republica_C_eopdf.pdf")}><span>Atos Dezembro/2013 (597 a 654)+Republicação</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2013_(572_a_596)pdf.pdf")}><span>Atos Novembro/2013 (572 a 596)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2013_(536_a_571)_+_Republica_C_Iespdf.pdf")}><span>Atos Outubro/2013 (536 a 571) + Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2013_(507_a_535)pdf.pdf")}><span>Atos Setembro/2013 (507 a 535)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2013_(465_a_506)_+_republica_C_Iespdf.pdf")}><span>Atos Agosto/2013 (465 a 506) + republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2013_(404_a_464)_+_Republica_C_Ies_pdf.pdf")}><span>Atos Julho/2013 (404 a 464) + Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2013_(370_a_403)pdf.pdf")}><span>Atos Junho/2013 (370 a 403)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Maio_2013_(316_a_369)_pdf.pdf")}><span>Atos Maio/2013 (316 a 369)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Abril_2013_(229_a_315)pdf.pdf")}><span>Atos Abril/2013 (229 a 315)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Mar_Co_2013_(158_a_228)pdf.pdf")}><span>Atos Março/2013 (158 a 228)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Fevereiro_2013_(_75_a_157_)pdf.pdf")}><span>Atos Fevereiro/2013 (75 a 157)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Janeiro_2013_(02_a_74)pdf.pdf")}><span>Atos Janeiro/2013 (02 a 74)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Dezembro_2012_(_1150_a_1359_)_pdf.pdf")}><span>Atos Dezembro/2012 (1150 a 1359)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2012' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Novembro_2012_(1099_-_1149)pdf.pdf")}><span>Atos Novembro/2012 (1099 - 1149)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2012_II_(1080_a_1098)pdf.pdf")}><span>Atos Outubro/2012 II (1080 a 1098)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Outubro_2012_(1020_ao_1079)pdf.pdf")}><span>Atos Outubro/2012 (1020 ao 1079)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2012_(1016-1019)pdf.pdf")}><span>Atos Setembro/2012 (1016-1019)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2012_(981-1015)pdf.pdf")}><span>Atos Setembro/2012 (981-1015)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Setembro_2012_(978-980)pdf.pdf")}><span>Atos Setembro/2012 (978-980)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2012_III_(947-977)pdf.pdf")}><span>Atos Agosto/2012 III (947-977)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2012_II_(919-946)_pdf.pdf")}><span>Atos Agosto/2012 II (919-946)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Agosto_2012_I_(864-918)_pdf.pdf")}><span>Atos Agosto/2012 I (864-918)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2012_III_(858-863)pdf.pdf")}><span>Atos Julho/2012 III (858-863)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Julho_2012_II_(802-857)_pdf.pdf")}><span>Atos Julho/2012 II (802-857)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_julho_2012_I_-_(744-801)pdf.pdf")}><span>Atos julho/2012 I - (744-801)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2012_V_(742_-_743)pdf.pdf")}><span>Atos Junho/2012 V (742 - 743)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_Junho_2012_IV_(733_-_741)pdf.pdf")}><span>Atos Junho/2012 IV (733 - 741)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_junho_2012_III_-_(729-732)pdf.pdf")}><span>Atos junho/2012 III - (729-732)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_junho_2012_II_-_(669-728)pdf.pdf")}><span>Atos junho/2012 II - (669-728)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_junho_2012_I_-_(665-688)pdf.pdf")}><span>Atos junho/2012 I - (665-688)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_maio_2012_IV_-_(653-664)_pdf.pdf")}><span>Atos maio/2012 IV - (653-664)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_maio_2012_III_-_(645-652)pdf.pdf")}><span>Atos maio/2012 III - (645-652)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_maio_2012_II_-_(577-644)pdf.pdf")}><span>Atos maio/2012 II - (577-644)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_maio_2012_I_-_(500-576)pdf.pdf")}><span>Atos maio/2012 I - (500-576)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_abril_2012_IV_-_(493-499)pdf.pdf")}><span>Atos abril/2012 IV - (493-499)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_abril_2012_III_-_(492)pdf.pdf")}><span>Atos abril/2012 III - (492)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_abril_2012_II_-_(489-491)pdf.pdf")}><span>Atos abril/2012 II - (489-491)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_abril_2012_-_I_(311-488)_pdf.pdf")}><span>Atos abril/2012 - I (311-488)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_mar_2012_-_(309-310)_pdf.pdf")}><span>Atos mar/2012 - (309-310)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_mar_2012_-_(276-308)pdf.pdf")}><span>Atos mar/2012 - (276-308)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_mar_2012_-_(188_-_275)_pdf.pdf")}><span>Atos mar/2012 - (188 - 275)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_fev_2012_-_(176_-_187)_pdf.pdf")}><span>Atos fev/2012 - (176 - 187)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_fev_2012_-_(079_-_175)pdf.pdf")}><span>Atos fev/2012 - (079 - 175)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Ato_(078)pdf.pdf")}><span>Ato (078)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_-_1267_2011_e_001-077_2012pdf.pdf")}><span>Atos - 1267/2011 e 001-077/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Atos_dez_2011_-_(1235-1266)pdf.pdf")}><span>Atos dez/2011 - (1235-1266)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <UltimaAtualizacao>
                Última Atualização em 26/10/2018 14:30
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default AtosAte2018;
