/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, SelectMes, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function DiarioBancos(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');
    const [mesSelecionado, setMesSelecionado] = useState<String>('01');

    const alteraMes = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const mes = event.target.value;
        setMesSelecionado(mes);
    };

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
    };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Mês/Ano:
                    <SelectMes id="mes" defaultValue={'01'} onChange={alteraMes}>
                        <option key='01' value='01'>
                            01
                        </option>
                        <option key='02' value='02'>
                            02
                        </option>
                        <option key='03' value='03'>
                            03
                        </option>
                        <option key='04' value='04'>
                            04
                        </option>
                        <option key='05' value='05'>
                            05
                        </option>
                        <option key='06' value='06'>
                            06
                        </option>
                        <option key='07' value='07'>
                            07
                        </option>
                        <option key='08' value='08'>
                            08
                        </option>
                        <option key='09' value='09'>
                            09
                        </option>
                        <option key='10' value='10'>
                            10
                        </option>
                        <option key='11' value='11'>
                            11
                        </option>
                        <option key='12' value='12'>
                            12
                        </option>
                    </SelectMes>
                    &nbsp;&nbsp;&nbsp;/
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' && mesSelecionado === '01' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO__.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO__.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO___.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO__.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO__.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_CEF_CONTA_MOVIMENTO__.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_CEF_CONTA_CONSIGNACAO__.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_BB_CONTA_MOVIMENTO_.pdf")}><span>FEC_BB_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_BB_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '02' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016__BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_CEF_CONTA_MOVIMENTO.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_CEF_CONTA_CONSIGNACAO.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_BB_CONTA_MOVIMENTO.pdf")}><span>FEC_BB_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "022016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_BB_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '03' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO_.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO ITAU - FOLHA DE PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO.pdf")}><span>CAIXA ECONOMICA FEDERAL - CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_FEC_CEF_CONTA_MOVIMENTO.pdf")}><span>FEC CEF CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC CEF CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_FEC_CEF_CONTA_CONSIGNACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC BB CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '04' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO DO BRASIL - CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO ITAU - FOLHA DE PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_CEF_CONTA_MOVIMENTO.pdf")}><span>FEC CEF CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC CEF CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_CEF_CONTA_CONSIGNACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_BB_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_BB_CONTA_MOVIMENTO.pdf")}><span>FEC BB CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC BB CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '05' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO DO BRASIL - CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO ITAU - FOLHA DE PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_CEF_CONTA_MOVIMENTO.pdf")}><span>FEC CEF CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC CEF CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_CEF_CONTA_CONSIGNACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_BB_CONTA_MOVIMENTO.pdf")}><span>FEC BB CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC BB CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '06' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA MOVIMENTO APLICAÇÃO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNAÇÃO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_BANCO_DO_BRASIL_-_CONTA_CONSIGANCAO_APLICACAO.pdf")}><span>BANCO DO BRASIL - CONTA CONSIGNAÇÃO APLICAÇÃO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_CAIXA_ECONOMICA_FEDERAL_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA ECONOMICA FEDERAL CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO_.pdf")}><span>BANCO ITAU - FOLHA DE PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_FEC_BB_CONTA_MOVIMENTO.pdf")}><span>FEC BB CONTA MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC BB CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC CEF CONTA MOVIMENTO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_FEC_CEF_CONTA_CONSIGNACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062016_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC CEF CONTA CONSIGNACAO APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>

                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '07' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '08' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '09' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '10' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "10_2016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '11' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' && mesSelecionado === '12' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_01.bb_conta_movimento_6168-9.pdf")}><span>01.bb conta movimento 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_02.bb_conta_movimento_aplicacao_6168-9.pdf")}><span>02.bb conta movimento aplicação 6168-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_03.bb_conta_movimento_8860-9.pdf")}><span>03.bb conta movimento 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_04.bb_conta_consignacao_aplicacao_8860-9.pdf")}><span>04.bb conta consignação aplicação 8860-9</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_05.cef_conta_movimento_83-0.pdf")}><span>05.cef conta movimento 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_06.cef_conta_movimento_aplicacao_83-0.pdf")}><span>06.cef conta movimento aplicação 83-0</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122016_07.banco_itau_-_folha_de_pagamento.pdf")}><span>07.banco itau - folha de pagamento</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '01' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "012015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '02' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "020215_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '03' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "032015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '04' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "042015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '05' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "052015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '06' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_FEC_CEF_CONTA_MOVIMENTO.pdf")}><span>FEC CEF CONTA MOVIMENTO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "062015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '07' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_BANCO_ITAU_-_FOHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "072015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '08' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO_.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_FEC_CONTA_MOVIMENTO_.pdf")}><span>FEC_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "082015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '09' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO__.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_FEC_CONTA_MOVIMENTO_.pdf")}><span>FEC_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_FEC_CONTA_CONSIGNACAO_.pdf")}><span>FEC_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "092015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '10' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO__.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO___.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO__.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_FEC_CONTA_MOVIMENTO__.pdf")}><span>FEC_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_FEC_CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_FEC_CONTA_CONSIGNACAO__.pdf")}><span>FEC_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "102015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '11' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_FEC_CONTA_MOVIMENTO.pdf")}><span>FEC_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_FEC_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_FEC_CONTA_CONSIGNACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "112015_FEC_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' && mesSelecionado === '12' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO_.pdf")}><span>BANCO_DO_BRASIL_-_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_BANCO_ITAU_-_FOLHA_DE_PAGAMENTO__.pdf")}><span>BANCO_ITAU_-_FOLHA_DE_PAGAMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL_-_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO_.pdf")}><span>CAIXA_ECONOMICA_FEDERAL-CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_CEF_CONTA_MOVIMENTO_.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_CEF_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_CEF_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_CEF_CONTA_CONSIGNACAO_.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_CEF_CONTA_CONSIGNACAO_APLICACAO.pdf")}><span>FEC_CEF_CONTA_CONSIGNACAO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_BB_CONTA_MOVIMENTO.pdf")}><span>FEC_BB_CONTA_MOVIMENTO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "122015_FEC_BB_CONTA_MOVIMENTO_APLICACAO.pdf")}><span>FEC_BB_CONTA_MOVIMENTO_APLICACAO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <br />
                <UltimaAtualizacao>
                Última Atualização em 12/03/2019 15:23
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default DiarioBancos;
