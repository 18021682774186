/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Imagem, Titulo, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import ImgEstruturaAdmLeg from '../../assets/CMC_ESTRUTURA_ORGANIZACIONAL_ADM_LEG_COLORIDO.svg';
import ImgEstruturaMesaExecutiva from '../../assets/CMC_ESTRUTURA_MESA_EXECUTIVA.svg';

function Organograma(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Titulo>ORGANOGRAMA ADMINISTRATIVO - LEGISLATIVO</Titulo>
                <Titulo>De acordo com a Lei 10131/2000 e suas alterações subsequentes e Resolução nº 03 de 15 de dezembro de 2000 e suas alterações subsequentes.</Titulo>
                <Imagem><img src={ImgEstruturaAdmLeg} alt="ORGANOGRAMA ADMINISTRATIVO - LEGISLATIVO"/></Imagem>
                <Titulo>ESTRUTURA DE ÓRGÃOS DE APOIO À ATIVIDADE POLITICO PARLAMENTAR</Titulo>
                <Titulo>De acordo com a Lei 10131/2000 e suas alterações subsequentes.</Titulo>
                <Titulo>OUVIDORIA DO MUNICÍPIO</Titulo>
                <Titulo>De acordo com a Lei nº 14.394/2013</Titulo>
                <Imagem><img src={ImgEstruturaMesaExecutiva} alt="ESTRUTURA DE ÓRGÃOS DE APOIO À ATIVIDADE POLITICO PARLAMENTAR E OUVIDORIA DO MUNICÍPIO"/></Imagem>
                <UltimaAtualizacao>
                Última Atualização em 21/08/2015 11:00
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default Organograma;
