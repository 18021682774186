import React from 'react';
import { Container } from './styles';

require('dotenv').config();

const Footer: React.FC = () => {

    return (
        <Container>
            <footer>
                <hr />
                <label>
                    CÂMARA MUNICIPAL DE CURITIBA
                </label>
                <br />
                <label>
                    RUA BARÃO DO RIO BRANCO, 720 - CURITIBA - PR - BRASIL - CEP: 80010-902
                </label>
                <br />
                <label>
                    FONE: (41) 3350-4500 - FAX: (41) 3350-4737
                </label>
                <br />
                <label>
                    EMAIL GERAL: CAMARA@CMC.PR.GOV.BR
                </label>
                <br />
                <label>
                    HORÁRIO DE FUNCIONAMENTO: DAS 8H ÀS 18H
                </label>
                <br/>
                <br />
                <label>
                    1997-2022 - CÂMARA MUNICIPAL DE CURITIBA - TODOS OS DIREITOS RESERVADOS
                </label>
            </footer>
        </Container>
    );
};

export default React.memo(Footer);
