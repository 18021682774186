/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao, TituloExplicacaoSemUnderline } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function ExecucaoOrcamentariaDesdobramento(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                        <option key='2012' value='2012'>
                            2012
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_12_2016.pdf")}><span>Resumo do Desdobr. Despesa - Jan a Dez 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_12_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Dez 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_11_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Nov 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_10_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Out 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_09_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Set 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_08_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Ago 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_06_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Jul 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_06_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Jun 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_05_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Mai 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_04_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Abr 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_03_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Mar 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_28_02_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Fev 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_01_2016.pdf")}><span>Resumo do Desdobramento da Despesa - Jan 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_12_2015.pdf")}><span>Resumo do Desdobr. Despesa - Jan a Dez 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_12_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Dez 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_11_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Nov 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_10_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Out 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_29_09_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Set 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_08_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Ago 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_07_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Jul 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_06_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Jun 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_05_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Mai 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_04_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Abr 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_03_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Mar 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_28_02_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Fev 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_01_2015.pdf")}><span>Resumo do Desdobramento da Despesa - Jan 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_12_2014.pdf")}><span>Resumo do Desdobr. Despesa - Jan a Dez 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_12_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Dez 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_11_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Nov 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_10_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Out 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_09_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Set 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_08_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Ago 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_07_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Jul 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_06_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Jun 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_05_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Mai 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_04_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Abr 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_03_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Mar 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_28_02_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Fev 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_01_2014.pdf")}><span>Resumo do Desdobramento da Despesa - Jan 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_12_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Dez 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_11_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Nov 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_10_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Out 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_09_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Set 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_08_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Ago 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_07_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Jul 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_06_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Jun 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_05_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Mai 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_04_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Abr 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_03_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Mar 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_28_02_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Fev 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_01_2013.pdf")}><span>Resumo do Desdobramento da Despesa - Jan 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2012' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_12_2012.pdf")}><span>Resumo do Desdobr. Despesa - Jan a Dez 2012 </span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_12_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Dez 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_11_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Nov 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_10_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Out 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_09_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Set 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_08_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Ago 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_07_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Jul 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_30_06_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Jun 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "exec_CMC_Contabilidade_31_05_2012.pdf")}><span>Resumo do Desdobramento da Despesa - Mai 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <br />
                <TituloExplicacaoSemUnderline>NOTA: O relatório detalhado de toda despesa empenhada, liquidada e paga, pode ser acessado na seção de "Receitas e Despesas".</TituloExplicacaoSemUnderline>
                <UltimaAtualizacao>
                Última Atualização em 12/03/2019 09:28
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default ExecucaoOrcamentariaDesdobramento;
