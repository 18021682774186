/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { BotaoComoLink, Container, ItemLista, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function PlanoContas(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Plano_de_contas_2016.pdf")}><span>Plano de contas 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Plano_de_contas_2015.pdf")}><span>Plano de contas 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 22/02/2019 10:55
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default PlanoContas;
