/* eslint-disable consistent-return */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AnexosLei4320 from './pages/AnexosLei4320';
import AtasAudienciaPublica from './pages/AtasAudienciaPublica';
import AtosAte2018 from './pages/AtosAte2018';
import AtosMesaAte2018 from './pages/AtosMesaAte2018';
import BensPermanentes from './pages/BensPermanentes';
import CargosESalarios from './pages/CargosESalarios';
import Contato from './pages/Contato';
import DemonstrativoSimplificado from './pages/DemonstrativoSimplificado';
import DespesaCategoriasEconomicas from './pages/DespesaCategoriasEconomicas';
import DespesaPessoal from './pages/DespesaPessoal';
import DespesaProgramaTrabalho from './pages/DespesaProgramaTrabalho';
import DisponibilidadeCaixa from './pages/DisponibilidadeCaixa';
import ExecucaoOrcamentariaDesdobramento from './pages/ExecucaoOrcamentariaDesdobramento';
import Fec from './pages/Fec';
import Glossario from './pages/Glossario';
import Home from './pages/Home';
import LdoLoaPpa from './pages/LdoLoaPpa';
import Legislacao from './pages/Legislacao';
import LicitacoesEContratos from './pages/LicitacoesEContratos';
import LrfGestaoFiscal from './pages/LrfGestaoFiscal';
import LrfResumido from './pages/LrfResumido';
import MaterialExpediente from './pages/MaterialExpediente';
import OrcamentoEFinancas from './pages/OrcamentoEFinancas';
import OrcamentoLegislativo from './pages/OrcamentoLegislativo';
import Organograma from './pages/Organograma';
import Patrimonio from './pages/Patrimonio';
import PlanoContas from './pages/PlanoContas';
import Portal from './pages/Portal';
import PortariasAte2018 from './pages/PortariasAte2018';
import PortariasEAtos from './pages/PortariasEAtos';
import PrestacaoContas from './pages/PrestacaoContas';
import ReceitaDespesaCategoria from './pages/ReceitaDespesaCategoria';
import ReceitasEDespesas from './pages/ReceitasEDespesas';
import RestosPagar from './pages/RestosPagar';
import ComparativoDespesa from './pages/ComparativoDespesa';
import BalancoFinanceiro from './pages/BalancoFinanceiro';
import BalancoPatrimonial from './pages/BalancoPatrimonial';
import DemonstrativoBalancoPatrimonial from './pages/DemonstrativoBalancoPatrimonial';
import DemonstrativoFluxoCaixa from './pages/DemonstrativoFluxoCaixa';
import DiarioBancos from './pages/DiarioBancos';
import LicitacoesLista from './pages/LicitacoesLista';
import ContratosLista from './pages/ContratosLista';

interface IPrivateRoute {
    component: any;
    exact?: boolean;
    path: string;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
    component: Component,
    ...rest
}): any => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
};

const Routes: React.FC<any> = () => (
    <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/portal" component={Portal} />
        <PrivateRoute exact path="/glossario" component={Glossario} />
        <PrivateRoute exact path="/legislacao" component={Legislacao} />
        <PrivateRoute exact path="/prestacao-contas" component={PrestacaoContas} />
        <PrivateRoute exact path="/organograma" component={Organograma} />
        <PrivateRoute exact path="/contato" component={Contato} />

        <PrivateRoute exact path="/receitas-e-despesas" component={ReceitasEDespesas} />
        <PrivateRoute exact path="/cargos-e-salarios" component={CargosESalarios} />
        <PrivateRoute exact path="/portarias-e-atos" component={PortariasEAtos} />
        <PrivateRoute exact path="/licitacoes-e-contratos" component={LicitacoesEContratos} />
        <PrivateRoute exact path="/orcamento-e-financas" component={OrcamentoEFinancas} />
        <PrivateRoute exact path="/patrimonio" component={Patrimonio} />

        <PrivateRoute exact path="/portarias-2018" component={PortariasAte2018} />
        <PrivateRoute exact path="/atos-mesa-2018" component={AtosMesaAte2018} />
        <PrivateRoute exact path="/atos-2018" component={AtosAte2018} />
        <PrivateRoute exact path="/bens-permanentes" component={BensPermanentes} />
        <PrivateRoute exact path="/material-expediente" component={MaterialExpediente} />
        <PrivateRoute exact path="/orcamento-legislativo" component={OrcamentoLegislativo} />
        <PrivateRoute exact path="/ldo-loa-ppa" component={LdoLoaPpa} />
        <PrivateRoute exact path="/lrf-resumido" component={LrfResumido} />
        <PrivateRoute exact path="/execucao-orcamentaria-desdobramento" component={ExecucaoOrcamentariaDesdobramento} />
        <PrivateRoute exact path="/fec" component={Fec} />
        <PrivateRoute exact path="/atas-audiencia-publica" component={AtasAudienciaPublica} />
        <PrivateRoute exact path="/plano-contas-legislativo" component={PlanoContas} />
        <PrivateRoute exact path="/lrf-completo" component={LrfGestaoFiscal} />
        <PrivateRoute exact path="/anexos-4320" component={AnexosLei4320} />


        <PrivateRoute exact path="/despesa-pessoal" component={DespesaPessoal} />
        <PrivateRoute exact path="/disponibilidade-caixa" component={DisponibilidadeCaixa} />
        <PrivateRoute exact path="/restos-a-pagar" component={RestosPagar} />
        <PrivateRoute exact path="/demostrativo-gestao-fiscal" component={DemonstrativoSimplificado} />

        <PrivateRoute exact path="/receita-despesa-categorias" component={ReceitaDespesaCategoria} />
        <PrivateRoute exact path="/despesa-categorias-economicas" component={DespesaCategoriasEconomicas} />
        <PrivateRoute exact path="/despesa-programa-trabalho" component={DespesaProgramaTrabalho} />
        <PrivateRoute exact path="/comparativo-despesa-autorizada" component={ComparativoDespesa} />
        <PrivateRoute exact path="/balanco-financeiro" component={BalancoFinanceiro} />
        <PrivateRoute exact path="/balanco-patrimonial" component={BalancoPatrimonial} />
        <PrivateRoute exact path="/demonstrativo-variacao-patrimonial" component={DemonstrativoBalancoPatrimonial} />
        <PrivateRoute exact path="/demonstrativo-fluxo-caixa" component={DemonstrativoFluxoCaixa} />
        <PrivateRoute exact path="/diario-bancos" component={DiarioBancos} />

        <PrivateRoute exact path="/compras-e-licitacoes" component={LicitacoesLista} />
        <PrivateRoute exact path="/contratos-e-convenios" component={ContratosLista} />

        <PrivateRoute path="*" component={Home} />
    </Switch>
);

export default Routes;
