import React, { ReactNode } from 'react';
import { Container } from './styles';

interface IButton {
    id?: string;
    name?: string;
    onClick?: any;
    type?: any;
    children?: ReactNode;
    rest?: string[];
    disabled?: boolean;
    onMouseOver?: any;
    title?: string;
}

const Button: React.FC<IButton> = ({ children, ...rest }) => {
    return <Container {...rest}>{children}</Container>;
};

export default Button;
