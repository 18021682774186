/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Texto, Titulo } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';

function Contato(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Titulo>ENTRE EM CONTATO</Titulo>
                <Texto>
                Para uma solicitação formal de informações de acordo com a Lei nº 12.527/2011, deve-se utilizar o Serviço de Informações ao Cidadão (SIC), clicando <a href="http://www.cmc.pr.gov.br/wspl/sistema/SicPedidoCriarForm.do?resetfull_action=&recurso=false" target="_blank" rel="noopener noreferrer">AQUI</a>.
                </Texto>
                <Texto>
                <b>Endereço:</b> Rua Barão do Rio Branco, 720 - Centro - Curitiba - PR - Brasil - Cep: 80010-902
                </Texto>
                <Texto>
                <b>Fone / Fax:</b> (41) 3350-4500 - (41) 3350-4737
                </Texto>
                <Texto>
                <b>Localização:</b> Clique <a href="http://maps.google.com.br/maps?f=q&source=s_q&hl=pt-BR&geocode=&q=rua+barao+do+rio+branco,+720,+curitiba,+parana&sll=-25.431377,-49.268274&sspn=0.013468,0.01929&gl=br&ie=UTF8&hq=&hnear=R.+Bar%C3%A3o+do+Rio+Branco,+720+-+Centro,+Curitiba+-+Paran%C3%A1,+80010-180&z=17" target="_blank" rel="noopener noreferrer">AQUI</a> para visualizar
                </Texto>
                <Texto>
                <b>Email:</b> <a href="mailto:camara@cmc.pr.gov.br">camara@cmc.pr.gov.br</a>
                </Texto>
            </Container>
        </DefaultLayout>
    );
}

export default Contato;
