/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Titulo, Texto } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { UltimaAtualizacao } from '../Glossario/styles';

function Portal(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Titulo>O Portal</Titulo>
                <Texto>
                O Portal de Transparência da Câmara Municipal de Curitiba foi criado em março de 2010 e é atualizado constantemente, em resposta às atualizações das legislações federal,
                estadual e municipal. Além de seguir recomendações da Controladoria Geral da União (<a href="http://www.cgu.gov.br/Publicacoes/transparencia-publica/brasil-transparente/arquivos/guia_portaltransparencia.pdf" target="_blank" rel="noopener noreferrer">CGU</a>), está adequado a exigências do Ministério Público do Estado do Paraná e atende aos
                padrões estabelecidos pela Lei de Responsabilidade Fiscal (lei complementar <a href="http://www.planalto.gov.br/ccivil_03/leis/LCP/Lcp101.htm" target="_blank" rel="noopener noreferrer">101/2000</a>), Lei Capiberibe (lei complementar <a href="http://www.planalto.gov.br/ccivil_03/leis/LCP/Lcp131.htm" target="_blank" rel="noopener noreferrer">131/2009</a>), decreto federal <a href="http://www.planalto.gov.br/ccivil_03/_ato2007-2010/2010/decreto/d7185.htm" target="blank" rel="noopener noreferrer">7.185/2010</a> ,
                Lei de Acesso à Informação (lei federal <a href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm" target="_blank" rel="noopener noreferrer">12.527/2011</a>), e Instrução Normativa <a href="http://www1.tce.pr.gov.br/conteudo/instrucao-normativa-n-89-de-28-de-fevereiro-de-2013/239895/area/10" target="_blank" rel="noopener noreferrer">89/2013</a> do Tribunal de Contas do Estado do Paraná.
                </Texto>
                <Texto>
                O objetivo é que o cidadão encontre aqui os dados que deseja sobre o trabalho do Legislativo Municipal, de forma autônoma e independente, por meio das ferramentas de transparência ativa.
                E, caso as dúvidas persistam, possa requisitar qualquer informação via Sistema de Informação ao Cidadão - além de contatar a Câmara Municipal pelos caminhos institucionais.
                </Texto>
                <Texto>
                O Portal da Transparência da Câmara Municipal divulga dados e informações da gestão pública, não apenas política, mas também administrativa.
                Tudo foi feito para que haja transparência e facilidade na leitura dos dados e dos números por qualquer pessoa, independentemente do nível de conhecimento orçamentário ou financeiro.
                </Texto>
                <UltimaAtualizacao>
                Última atualização em 20/08/2015 08:00
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default Portal;
