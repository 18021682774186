import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-gap: 10px;
    background-color: #fff;
    color: #444;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 200px 200px 1px;
    background-color: #fff;
    color: #444;
`;

export const Titulo = styled.div`
    grid-column: 1 / span 2;
    color: #000;
    font-size: 46px;
    font-weight: 700;
    margin-left:5px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
`;

export const Lupa = styled.div`
    grid-column: 3 ;
    grid-row: 1 / span 2;
`;

export const Botoes = styled.div`
    grid-column: 1 / span 2;
    grid-row: 2 ;
    margin-left: 15px;
    table {
        tbody {
            tr {
                height: 80px;
            }
        }
    }
`;

export const BotaoHome = styled.button`
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    width: 180px;
    height: 60px;
    border: none;
    border-radius: 8px;
    color: #fff;
    background: #20733F;
    padding: 7px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
        background:  #84ca9a ;
    }
`;

