/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao, TituloExplicacao, Texto } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function BensPermanentes(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_-_12_2016.pdf")}><span>Bens permanentes - 12/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_-_12_2016(1).pdf")}><span>Bens permanentes - 12/2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_adquiridos_no_mes_de_outubro_de_2016.pdf")}><span>Bens permanentes adquiridos no mês de outubro de 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_adquiridos_no_mes_de_agosto_de_2016.pdf")}><span>Bens permanentes adquiridos no mês de agosto de 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_adquiridos_no_mes_de_setembro_de_2016.pdf")}><span>Bens permanentes adquiridos no mês de setembro de 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_Permanentes_Adquiridos_no_mes_de_julho_de_2016.pdf")}><span>Bens Permanentes Adquiridos no mês de julho de 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_adquiridos_em_abril_2016.pdf")}><span>Bens permanentes adquiridos em abril 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_adquiridos_em_marco_2016.pdf")}><span>Bens permanentes adquiridos em março 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_janeiro_a_dezembro_2015.pdf")}><span>Bens permanentes janeiro a dezembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_agosto_2015.pdf")}><span>Bens permanentes agosto 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_junho_2015.pdf")}><span>Bens permanentes junho 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_maio_2015.pdf")}><span>Bens permanentes maio 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_marco_2015.pdf")}><span>Bens permanentes março 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_fevereiro_2015.pdf")}><span>Bens permanentes fevereiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Bens_permanentes_janeiro_2015.pdf")}><span>Bens permanentes janeiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <br />
                <TituloExplicacao>NOTAS:</TituloExplicacao>
                <Texto>
                1) Somente serão apresentados arquivos dos meses em que houver aquisição de Bens Permanentes.
                </Texto>
                <Texto>
                2) A Câmara Municipal de Curitiba não realiza doações, cessões e permuta de Bens Permanentes.
                Após avaliados como inservíveis pela Comissão de Baixa de Bens os mesmos são devolvidos para a Prefeitura Municipal de Curitiba que dará o encaminhamento apropriado.
                </Texto>
                <UltimaAtualizacao>
                Última Atualização em 17/01/2020 09:39
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default BensPermanentes;
