/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function DespesaCategoriasEconomicas(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                        <option key='2012' value='2012'>
                            2012
                        </option>
                        <option key='2011' value='2011'>
                            2011
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2016.pdf")}><span>Despesa Cat. Econ. - Janeiro a Dezembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_12_2016.pdf")}><span>Despesa Cat. Econ. - Dezembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_11_2016.pdf")}><span>Despesa Cat. Econ. - Novembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_10_2016.pdf")}><span>Despesa Cat. Econ. - Outubro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_09_2016.pdf")}><span>Despesa Cat. Econ. - Setembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_08_2016.pdf")}><span>Despesa Cat. Econ. - Agosto 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_07_2016.pdf")}><span>Despesa Cat. Econ. - Julho 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2016.pdf")}><span>Despesa Cat. Econ. - Junho 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_05_2016.pdf")}><span>Despesa Cat. Econ. - Maio 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_04_2016.pdf")}><span>Despesa Cat. Econ. - Abril 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_03_2016.pdf")}><span>Despesa Cat. Econ. - Março 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_28_02_2016.pdf")}><span>Despesa Cat. Econ. - Fevereiro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_01_2016.pdf")}><span>Despesa Cat. Econ. - Janeiro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2015.pdf")}><span>Despesa Cat. Econ. - Janeiro a Dezembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_12_2015.pdf")}><span>Despesa Cat. Econ. - Dezembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_11_2015.pdf")}><span>Despesa Cat. Econ. - Novembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_10_2015.pdf")}><span>Despesa Cat. Econ. - Outubro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_09_2015.pdf")}><span>Despesa Cat. Econ. - Setembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_08_2015.pdf")}><span>Despesa Cat. Econ. - Agosto 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_07_2015.pdf")}><span>Despesa Cat. Econ. - Julho 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2015.pdf")}><span>Despesa Cat. Econ. - Junho 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_05_2015.pdf")}><span>Despesa Cat. Econ. - Maio 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_04_2015.pdf")}><span>Despesa Cat. Econ. - Abril 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_03_2015.pdf")}><span>Despesa Cat. Econ. - Março 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_28_02_2015.pdf")}><span>Despesa Cat. Econ. - Fevereiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_01_2015.pdf")}><span>Despesa Cat. Econ. - Janeiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2014.pdf")}><span>Despesa Cat. Econ. - Janeiro a Dezembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_12_2014.pdf")}><span>Despesa Cat. Econ. - Dezembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_11_2014.pdf")}><span>Despesa Cat. Econ. - Novembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_10_2014.pdf")}><span>Despesa Cat. Econ. - Outubro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_09_2014.pdf")}><span>Despesa Cat. Econ. - Setembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_08_2014.pdf")}><span>Despesa Cat. Econ. - Agosto 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_07_2014.pdf")}><span>Despesa Cat. Econ. - Julho 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2014.pdf")}><span>Despesa Cat. Econ. - Junho 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_05_2014.pdf")}><span>Despesa Cat. Econ. - Maio 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_04_2014.pdf")}><span>Despesa Cat. Econ. - Abril 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_03_2014.pdf")}><span>Despesa Cat. Econ. - Março 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_28_02_2014.pdf")}><span>Despesa Cat. Econ. - Fevereiro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_01_2014.pdf")}><span>Despesa Cat. Econ. - Janeiro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2013.pdf")}><span>Despesa Cat. Econ. - Janeiro a Dezembro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_12_2013.pdf")}><span>Despesa Cat. Econ. - Dezembro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_11_2013.pdf")}><span>Despesa Cat. Econ. - Novembro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_10_2013.pdf")}><span>Despesa Cat. Econ. - Outubro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_09_2013.pdf")}><span>Despesa Cat. Econ. - Setembro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_08_2013.pdf")}><span>Despesa Cat. Econ. - Agosto 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_07_2013.pdf")}><span>Despesa Cat. Econ. - Julho 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2013.pdf")}><span>Despesa Cat. Econ. - Junho 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_05_2013.pdf")}><span>Despesa Cat. Econ. - Maio 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_04_2013.pdf")}><span>Despesa Cat. Econ. - Abril 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_03_2013.pdf")}><span>Despesa Cat. Econ. - Março 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_28_02_2013.pdf")}><span>Despesa Cat. Econ. - Fevereiro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_01_2013.pdf")}><span>Despesa Cat. Econ. - Janeiro 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2012' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2012.pdf")}><span>Despesa Cat. Econ. - Dezembro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE1_CMC_Contabilidade_31_12_2012.pdf")}><span>Despesa Cat. Econ. - Janeiro a Dezembro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_11_2012.pdf")}><span>Despesa Cat. Econ. - Novembro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_10_2012.pdf")}><span>Despesa Cat. Econ. - Outubro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_09_2012.pdf")}><span>Despesa Cat. Econ. - Setembro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_08_2012.pdf")}><span>Despesa Cat. Econ. - Agosto 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_07_2012.pdf")}><span>Despesa Cat. Econ. - Julho 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2012.pdf")}><span>Despesa Cat. Econ. - Junho 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_05_2012.pdf")}><span>Despesa Cat. Econ. - Maio 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_04_2012.pdf")}><span>Despesa Cat. Econ. - Abril 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_03_2012.pdf")}><span>Despesa Cat. Econ. - Março 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_02_2012.pdf")}><span>Despesa Cat. Econ. - Fevereiro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_01_2012.pdf")}><span>Despesa Cat. Econ. - Janeiro 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2011' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_12_2011.pdf")}><span>Despesa Cat. Econ. - Dezembro 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_11_2011.pdf")}><span>Despesa Cat. Econ. - Novembro 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_10_2011.pdf")}><span>Despesa Cat. Econ. - Outubro 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_09_2011.pdf")}><span>Despesa Cat. Econ. - Setembro 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_31_08_2011.pdf")}><span>Despesa Cat. Econ. - Agosto 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_29_07_2011.pdf")}><span>Despesa Cat. Econ. - Julho 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DCE_CMC_Contabilidade_30_06_2011.pdf")}><span>Despesa Cat. Econ. - Junho 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <br />
                <UltimaAtualizacao>
                Última Atualização em 12/03/2019 09:25
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default DespesaCategoriasEconomicas;
