/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FaFilePdf, FaAngleRight } from 'react-icons/fa';
import { Container, ItemLista, TituloLista, ListaInterna, UltimaAtualizacao, BotaoComoLink } from './styles';
import DefaultLayout from '../_layouts/default';
import { download } from 'src/utils/downloadArquivo';
import TituloInterno from 'src/components/TituloInterno';

function PrestacaoContas(): React.ReactNode {
    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/>Relatórios Periódicos das Atividades Parlamentares</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "RelatorioPeriodicoAtividadesParlamentares_2017.pdf")}><span>Ano 2017</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "")}><span>Ano 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "")}><span>Ano 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "")}><span>Ano 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "")}><span>Ano 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Prestação de Contas do Município</TituloLista>
                <ul>
                    <ItemLista><span>Autos Digitais - Processo nº 196134/2012 - Parecer Prévio do Tribunal de Contas do Paraná</span>
                        <ul>
                            <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-19613412-principal.pdf")}><span>Processo-19613412.pdf</span></BotaoComoLink><FaFilePdf /></ItemLista>
                            <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-19613412-anexo-50177412.pdf")}><span>Processo-19613412-anexo-50177412.pdf</span></BotaoComoLink><FaFilePdf /></ItemLista>
                            <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-19613412-anexo-55397911.pdf")}><span>Processo-19613412-anexo-55397911.pdf</span></BotaoComoLink><FaFilePdf /></ItemLista>
                        </ul>
                    </ItemLista>
                    <ItemLista><span>Autos Digitais - Processo nº 165690/2011 - Parecer Prévio do Tribunal de Contas do Paraná</span>
                        <ul>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-103607963_Termo-de-Autuacao.pdf")}><span>1 - Termo de Autuação</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-103811438-oficio-inicial-e-documentos.pdf")}><span>2 - Ofício Inicial e Documentos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-103858658_oficio-inicial-e-documentos-2-3.pdf")}><span>3 - Ofício Inicial e Documentos - 2/3</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-103950614_oficio-inicial-e-documentos-3-3.pdf")}><span>4 - Ofício Inicial e Documentos - 3/3</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104020521_Termo-de-distribuicao-6089-11-DP.pdf")}><span>5 - Termo de distribuição - 6089/11 - DP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104033178_Ar-de-devolucao-dos-Autos-Fisicos.pdf")}><span>6 - Ar de devolução dos Autos Fìsicos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104045241_Instrucao-3383-11-DCM.pdf")}><span>7 - Instrução - 3383/11 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104057648_Despacho-295-12-GCHGH.pdf")}><span>8 - Despacho - 295/12 - GCHGH</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104108367_Certidao-de-Publicacao-AOTC-17-12-GCHGH.pdf")}><span>9 - Certidão de Publicação AOTC - 17/12 - GCHGH</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104418532_Oficio-de-contraditorio-177-12-DCM.pdf")}><span>10 - Ofício de contraditório - 177/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104436455_Oficio-de-contraditorio-179-12-DCM.pdf")}><span>11 - Ofício de contraditório - 179/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104451487_Juntada-de-AR-oficio-179-12-OCN-DCM.pdf")}><span>12 - Juntada de AR ofício 179/12-OCN-DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104502784_Juntada-de-AR-Oficio-n.-177-12-OCN-DCM.pdf")}><span>13 - Juntada de AR Ofício n. 177/12- OCN-DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104515254_Recibo-de-Peticao-Intermediaria.pdf")}><span>14 - Recibo de Petição Intermediária</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104531880_Peticao.pdf")}><span>15 - Petição</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104550099_Outros-Documentos.pdf")}><span>16 - Outros Documentos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104603413_Despacho-272-12-DCM.pdf")}><span>17 - Despacho - 272/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104621132_Despacho-638-12-GCHGH.pdf")}><span>18 - Despacho - 638/12 - GCHGH</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104634977_Informacao-1771-12-DP.pdf")}><span>19 - Informação - 1771/12 - DP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104646837_Recibo-de-Peticao-Intermediaria.pdf")}><span>14 - Recibo de Petição Intermediária</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104706213_Peticao.pdf")}><span>15 - Petição</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104718073_Resp.-ao-oficio-179-12-cc-pf-1-8.pdf")}><span>22 - Resp. ao ofício 179/12-cc-pf - 1/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104732902_Resp.-Of.-179-12-CC-PF-2-8.pdf")}><span>23 - Resp. Of. 179/12-CC-PF - 2/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104819623_Resp.-Of.-179-12-CC-PF-3-8.pdf")}><span>24 - Resp. Of. 179/12-CC-PF - 3/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-104924173_Resp.-Of.-179-12-CC-PF-4-8.pdf")}><span>25 - Resp. Of. 179/12-CC-PF - 4/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105132603_Resp.-Of.-179-12-CC-PF-5-8.pdf")}><span>26 - Resp. Of. 179/12-CC-PF - 5/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105233012_Resp.-Of.-179-12-CC-PF-6-8.pdf")}><span>27 - Resp. Of. 179/12-CC-PF - 6/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110754077_Resp.-Of.-179-12-CC-PF-7-8.pdf")}><span>28 - Resp. Of. 179/12-CC-PF - 7/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105430377_Resp.-Of.-179-12-CC-PF-8-8.pdf")}><span>29 - Resp. Of. 179/12-CC-PF - 8/8</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105500050_Despacho-355-12-DCM.pdf")}><span>30 - Despacho - 355/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105515738_Resposta-ao-oficio-177-12-cc-pf.pdf")}><span>31 - Resposta ao ofício 177/12-cc-pf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105533613_Despacho-847-12-GCHGH.pdf")}><span>32 - Despacho - 847/12 - GCHGH</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105547332_Informacao-2493-12-DP.pdf")}><span>33 - Informação - 2493/12 - DP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105600083_Recibo-de-Peticao-Intermediaria.pdf")}><span>14 - Recibo de Petição Intermediária</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105617834_Peticao.pdf")}><span>15 - Petição</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105715070_Outros-Documentos.pdf")}><span>16 - Outros Documentos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105731883_Despacho-1212-12-GCHGH.pdf")}><span>37 - Despacho - 1212/12 - GCHGH</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105746024_Termo-de-distribuicao-1215-12-DP.pdf")}><span>38 - Termo de distribuição - 1215/12 - DP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105759212_Despacho-1244-12-GCCMNS.pdf")}><span>39 - Despacho - 1244/12 - GCCMNS</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105816665_AR-de-devolucao-dos-autos-fisicos.pdf")}><span>40 - AR de devolução dos autos físicos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105902541_Recibo-de-Peticao-Intermediaria.pdf")}><span>14 - Recibo de Petição Intermediária</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105924933_Peticao.pdf")}><span>15 - Petição</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-105937652_Despacho-2108-12-DCM.pdf")}><span>43 - Despacho - 2108/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110023779_Despacho-2147-12-GCCMNS.pdf")}><span>44 - Despacho - 2147/12 - GCCMNS</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110041248_Instrucao-3588-12-DCM.pdf")}><span>45 - Instrução - 3588/12 - DCM</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110116405_Termo-de-devolucao-dos-autos-fisicos.pdf")}><span>46 - Termo de devolução dos autos físicos</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110133343_Parecer-Ministerial-15994-12-SMPjTC.pdf")}><span>47 - Parecer Ministerial - 15994/12 - SMPjTC</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110146140_Certidao-de-Sessao-376-13-S2C.pdf")}><span>48 - Certidão de Sessão - 376/13 - S2C</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110203969_Acordao-de-Parecer-Previo-122-13-S2C.pdf")}><span>49 - Acórdão de Parecer Prévio - 122/13 - S2C</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110220376_Certidao-de-Publicacao-DETC-332-13-S2C.pdf")}><span>50 - Certidão de Publicação DETC - 332/13 - S2C</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110236126_Certidao-de-transito-em-julgado-429-13-S2C.pdf")}><span>51 - Certidão de trânsito em julgado - 429/13 - S2C</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110256315_Informacao-1743-13-DEX.pdf")}><span>52 - Informação - 1743/13 - DEX</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110341660_Oficio-1021-13-GP.pdf")}><span>53 - Ofício - 1021/13 - GP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110357692_Despacho-1131-13-GCCMNS.pdf")}><span>54 - Despacho - 1131/13 - GCCMNS</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110418177_Certidao-de-Juntada.pdf")}><span>55 - Certidão de Juntada</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110435835_Procuracao.pdf")}><span>56 - Procuração</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110513258_Despacho-1633-13-GCCMNS.pdf")}><span>57 - Despacho - 1633/13 - GCCMNS</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110527883_Despacho-28-15-DP.pdf")}><span>58 - Despacho - 28/15 - DP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110546337_Despacho-657-15-GP.pdf")}><span>59 - Despacho - 657/15 - GP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16569011-110602978_Oficio-258-15-GP.pdf")}><span>60 - Ofício - 258/15 - GP</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                        </ul>
                    </ItemLista>
                    <ItemLista><span>Autos Digitais - Processo nº 166437/2003 - Parecer Prévio do Tribunal de Contas do Paraná</span>
                        <ul>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-principal.pdf")}><span>processo-16643703-principal.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-1253202.pdf")}><span>processo-16643703-anexo-1253202.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-16738302.pdf")}><span>processo-16643703-anexo-16738302.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-20006202.pdf")}><span>processo-16643703-anexo-20006202.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-22936706.pdf")}><span>processo-16643703-anexo-22936706.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-23302502.pdf")}><span>processo-16643703-anexo-23302502.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-32165002.pdf")}><span>processo-16643703-anexo-32165002.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-32870002.pdf")}><span>processo-16643703-anexo-32870002.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-46044702.pdf")}><span>processo-16643703-anexo-46044702.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-47087602.pdf")}><span>processo-16643703-anexo-47087602.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-7901702.pdf")}><span>processo-16643703-anexo-7901702.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                            <ListaInterna><BotaoComoLink type="button" onClick={ (e) => download(e, "processo-16643703-anexo-9253003.pdf")}><span>processo-16643703-anexo-9253003.pdf</span></BotaoComoLink><FaFilePdf /></ListaInterna>
                        </ul>
                    </ItemLista>
                </ul>
                <UltimaAtualizacao>
                Última Atualização em 17/08/2015 08:00
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default PrestacaoContas;
