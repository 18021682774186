/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container, TabelaEscolha, TabelaResultado } from './styles';
import DefaultLayout from '../_layouts/default';
import api from '../../service/api';
import TituloInterno from 'src/components/TituloInterno';
import ModalDetalhes from 'src/components/ModalLicitacao';
import Select from 'src/components/layout/Select';
import { Form } from '@unform/web';
import SelectAno from 'src/components/layout/SelectAno';
import { BotaoComoLink } from './styles';
import SelectSituacao from 'src/components/layout/SelectSituacao';

function LicitacoesLista(): React.ReactNode {

    const formRef = useRef(null);

    const [modalidades, setModalidades] = useState([]);

    const [modalDetalhes, setModalDetalhes] = useState(false);

    const [idModalDetalhes, setIdModalDetalhes] = useState(0);

    const [licitacoes, setLicitacoes] = useState([]);

    const alteraAno = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = e.target.value;
        const sitId = formRef.current.getFieldValue('sitId');
        const lmoId = formRef.current.getFieldValue('lmoId');
        carregaLicitacoes(ano,sitId,lmoId);
    };

    const alteraSituacao = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = formRef.current.getFieldValue('ano');
        const sitId = Number(e.target.value);
        const lmoId = formRef.current.getFieldValue('lmoId');
        carregaLicitacoes(ano,sitId,lmoId);
    };

    const alteraModalidade = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = formRef.current.getFieldValue('ano');
        const sitId = formRef.current.getFieldValue('sitId');
        const lmoId = Number(e.target.value);
        carregaLicitacoes(ano,sitId,lmoId);
    };

    function verDetalhes(id: number): void {
        setIdModalDetalhes(id);
        setModalDetalhes(true);
    }

    const carregaModalidades = useCallback(async () => {
        try {
            const response = await api.get('/modalidades');
            const data = response.data.map((modalidade: any) => {
                return {
                    label: modalidade.lmoNome,
                    value: modalidade.lmoId,
                };
            });
            setModalidades(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const carregaLicitacoes = useCallback(async (ano: string, sitId: number, lmoId: number) => {
        try {
            const response = await api.get(`/licitacoes/${ano}/${sitId}/${lmoId}`);
            const data = response.data;
            setLicitacoes(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        async function carrega(): Promise<void> {
            carregaModalidades();
            carregaLicitacoes('2016',10,-1);
        }
        carrega();
    }, []);

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
            <Form ref={formRef} onSubmit={null} initialData={{ sitId: '3' }}>
                    <TabelaEscolha>
                        <thead>
                            <tr>
                                <td>
                                    <SelectAno name="ano" label="Ano" defaultValue={'2016'} onChange={alteraAno}/>
                                </td>
                                <td>
                                    <Select name="lmoId" label="Modalidade" options={modalidades} onChange={alteraModalidade}/>
                                </td>
                                <td>
                                    <SelectSituacao name="sitId" label="Situação" defaultValue={'10'} onChange={alteraSituacao}/>
                                </td>
                            </tr>
                        </thead>
                    </TabelaEscolha>
                    <br />
                    {licitacoes.length > 0 ? (
                    <TabelaResultado>
                        <thead>
                            <tr>
                                <td style={{textAlign: 'center'}}>Número</td>
                                <td style={{textAlign: 'center'}}>Modalidade</td>
                                <td style={{textAlign: 'center'}}>Data da sessão pública</td>
                                <td style={{textAlign: 'center'}}>Descrição</td>
                                <td style={{textAlign: 'center'}}>Situação</td>
                                <td style={{textAlign: 'center'}}>Valor estimado</td>
                            </tr>
                        </thead>
                        <tbody>
                            {licitacoes.map((licitacao) => (
                                <tr key={licitacao.aLicCodigo}>
                                    <td style={{textAlign: 'center'}}>
                                        <BotaoComoLink
                                            type="button"
                                            onClick={
                                                () => verDetalhes(licitacao.aLicCodigo)
                                            }>
                                            {licitacao.aLicNrLicitacao}/{licitacao.aLicAno}
                                        </BotaoComoLink>
                                    </td>
                                    <td>{licitacao.lmoNome}</td>
                                    <td style={{textAlign: 'center'}}>{licitacao.aLicDtAbertura}</td>
                                    <td>{licitacao.aLicDescricaoObjeto}</td>
                                    <td style={{textAlign: 'center'}}>{licitacao.sitNome}</td>
                                    <td>{licitacao.aLicRValor}</td>
                                </tr>
                            ))}
                        </tbody>
                    </TabelaResultado>
                    ) : (null)}
               </Form>
               {modalDetalhes && (
                   <ModalDetalhes id={idModalDetalhes.toString()} modalLicitacao fechaModalLicitacao={() => setModalDetalhes(false)}/>
               )}
            </Container>
        </DefaultLayout>
    );
}

export default LicitacoesLista;
