import React from 'react';
import { ThemeProvider } from 'styled-components';
import Router from './Router';
import GlobalStyle from './styles/global';
import defaultTheme from './styles/theme';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

export default function App(): React.ReactElement {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Router />
            <GlobalStyle />
        </ThemeProvider>
    );
}
