/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, TituloLista } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function LicitacoesEContratos(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/><Link to="/compras-e-licitacoes">Compras e licitações</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/contratos-e-convenios">Contratos e convênios</Link></TituloLista>
            </Container>
        </DefaultLayout>
    );
}

export default LicitacoesEContratos;
