/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container, ContainerAno, ContainerEscolha, TabelaResultado, TituloExplicacaoSemUnderline, BotaoComoLink } from './styles';
import DefaultLayout from '../_layouts/default';
import api from '../../service/api';
import TituloInterno from 'src/components/TituloInterno';
import ModalDetalhes from 'src/components/ModalContrato';
import { Form } from '@unform/web';
import SelectAnoContrato from 'src/components/layout/SelectAnoContrato';
import { UltimaAtualizacao } from '../OrcamentoEFinancas/styles';

function ContratosLista(): React.ReactNode {

    const formRef = useRef(null);

    const [modalDetalhes, setModalDetalhes] = useState(false);

    const [idModalDetalhes, setIdModalDetalhes] = useState(0);

    const [contratos, setContratos] = useState([]);

    const alteraAno = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = e.target.value;
        carregaContratos(ano);
    };

    function verDetalhes(id: number): void {
        setIdModalDetalhes(id);
        setModalDetalhes(true);
    }

    const carregaContratos = useCallback(async (ano: string) => {
        try {
            const response = await api.get(`/contratos/${ano}`);
            const data = response.data;
            setContratos(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        async function carrega(): Promise<void> {
            carregaContratos('2016');
        }
        carrega();
    }, []);

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>

            <ContainerEscolha>
                    <TituloExplicacaoSemUnderline>
                    CONTRATOS E CONVÊNIOS
                    </TituloExplicacaoSemUnderline>
            </ContainerEscolha>
            <br />
            <Form ref={formRef} onSubmit={null}>
                <ContainerAno>
                    <div><SelectAnoContrato name="ano" label="Ano" defaultValue={'2016'} onChange={alteraAno}/></div>
                </ContainerAno>
                    <br />
                    {contratos.length > 0 ? (
                    <TabelaResultado>
                        <thead>
                            <tr>
                                <td style={{textAlign: 'center'}}>Número</td>
                                <td style={{textAlign: 'center'}}>Tipo do documento</td>
                                <td style={{textAlign: 'center'}}>Publicação no DOM</td>
                                <td style={{textAlign: 'center'}}>Autorização</td>
                                <td>Contratado</td>
                                <td>Objeto</td>
                                <td style={{textAlign: 'center'}}>Preço inicial</td>
                                <td style={{textAlign: 'center'}}>Valor atualizado</td>
                            </tr>
                        </thead>
                        <tbody>
                            {contratos.map((contrato) => (
                                <tr key={contrato.aCtrCodigo}>
                                    <td style={{textAlign: 'center'}}>
                                        <BotaoComoLink
                                            type="button"
                                            onClick={
                                                () => verDetalhes(contrato.aCtrCodigo)
                                            }>
                                            {contrato.aCtrNumero}/{contrato.aCtrAno}
                                        </BotaoComoLink>
                                    </td>
                                    <td style={{textAlign: 'center'}}>{contrato.ctpNome}</td>
                                    <td style={{textAlign: 'center'}}>{contrato.aCtrDataDom}</td>
                                    <td style={{textAlign: 'center'}}>{contrato.aCtrData}</td>
                                    <td>{contrato.aCtrContratado}</td>
                                    <td>{contrato.aCtrObjeto}</td>
                                    <td style={{textAlign: 'center'}}>{contrato.aCtrPrecoInicial}</td>
                                    <td style={{textAlign: 'center'}}>{contrato.aCtrValorAtualizado}</td>
                                </tr>
                            ))}
                        </tbody>
                    </TabelaResultado>
                    ) : (null)}
               </Form>
               <UltimaAtualizacao>
               Última Atualização em 25/10/2019 09:01
               </UltimaAtualizacao>
               {modalDetalhes && (
                   <ModalDetalhes id={idModalDetalhes.toString()} modalContrato fechaModalContrato={() => setModalDetalhes(false)}/>
               )}
            </Container>
        </DefaultLayout>
    );
}

export default ContratosLista;
