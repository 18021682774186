import React, { useCallback, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import Modal from 'react-modal';
import api from 'src/service/api';
import { download } from 'src/utils/downloadArquivo';
import Fechar from '../layout/button/Fechar';
import { ContainerModal, Descricao, Scroll, TabelaResultado, UltimaAtualizacao, BotaoComoLink, CentralizaBotao, BotaoComoLinkContrato } from './styles';

interface IModalLicitacao {
    fechaModalLicitacao?: any;
    modalLicitacao?: any;
    id?: any;
}

const ModalLicitacao: React.FC<IModalLicitacao> = ({ fechaModalLicitacao, modalLicitacao, id }) => {

    const [lmoNome, setLmoNome] = useState('');
    const [aLicAno, setALicAno] = useState('');
    const [aLicDescricaoObjeto, setALicDescricaoObjeto] = useState('');
    const [aLicDtAbertura, setALicDtAbertura] = useState('');
    const [aLicDtHomologacao, setALicDtHomologacao] = useState('');
    const [aLicDtLancRatif, setALicDtLancRatif] = useState('');
    const [aLicDtNovaAbertura, setALicDtNovaAbertura] = useState('');
    const [aLicUltimaAtualizacao, setALicUltimaAtualizacao] = useState('');
    const [aLicEmpresa, setALicEmpresa] = useState('');
    const [aLicJustificativa, setALicJustificativa] = useState('');
    const [aLicNrLicitacao, setALicNrLicitacao] = useState('');
    const [aLicRValor, setALicRValor] = useState('');
    const [sitNome, setSitNome] = useState('');

    const [licitacaoAnexos, setLicitacaoAnexos] = useState([]);

    const [contratosLicitacao, setContratosLicitacao] = useState([]);

    function fechaHandler(e: any): any {
            fechaModalLicitacao(e.target.value);
    }

    const carregaLicitacao = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/licitacao/${id}`);
            console.log(response.data);
            setLmoNome(response.data.lmoNome);
            setALicAno(response.data.aLicAno);
            setALicDescricaoObjeto(response.data.aLicDescricaoObjeto);
            setALicDtAbertura(response.data.aLicDtAbertura);
            setALicUltimaAtualizacao(response.data.aLicUltimaAtualizacao);
            setALicDtHomologacao(response.data.aLicDtHomologacao);
            setALicDtLancRatif(response.data.aLicDtLancRatif);
            setALicDtNovaAbertura(response.data.aLicDtNovaAbertura);
            setALicEmpresa(response.data.aLicEmpresa);
            setALicJustificativa(response.data.aLicJustificativa);
            setALicNrLicitacao(response.data.aLicNrLicitacao);
            setALicRValor(response.data.aLicRValor);
            setSitNome(response.data.sitNome);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const carregaLicitacaoAnexos = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/licitacao-anexos/${id}`);
            setLicitacaoAnexos(response.data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const carregaLicitacaoContratos = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/contratos-licitacao/${id}`);
            setContratosLicitacao(response.data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        async function carrega(): Promise<void> {
            carregaLicitacao(id);
            carregaLicitacaoAnexos(id);
            carregaLicitacaoContratos(id);
        }
        carrega();
    }, [carregaLicitacao, carregaLicitacaoAnexos, carregaLicitacaoContratos, id]);

    return (
        <>
            <Modal
                isOpen={modalLicitacao}
                onRequestClose={fechaHandler}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(216, 222, 233, 0.75)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        border: '1px solid #000',
                        backgroundColor: '#fff',
                        padding: '5px',
                    },
                }}
                ariaHideApp={false}>
                <ContainerModal>
                    <Scroll>
                    <h1>Detalhes da licitação</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td><Descricao>Modalidade:</Descricao></td>
                                <td>{lmoNome}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Número/Ano:</Descricao></td>
                                <td>{aLicNrLicitacao+'/'+aLicAno}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Descrição:</Descricao></td>
                                <td style={{textAlign: 'justify'}}>{aLicDescricaoObjeto}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Valor máximo:</Descricao></td>
                                <td>{aLicRValor}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Data do edital:</Descricao></td>
                                <td>{aLicDtLancRatif}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Data de abertura:</Descricao></td>
                                <td>{aLicDtAbertura}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Nova data de abertura:</Descricao></td>
                                <td>{aLicDtNovaAbertura}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Empresa vencedora:</Descricao></td>
                                <td>{aLicEmpresa}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Data de homologação:</Descricao></td>
                                <td>{aLicDtHomologacao}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Situação:</Descricao></td>
                                <td>{sitNome}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Justificativa:</Descricao></td>
                                <td style={{textAlign: 'justify'}}>{aLicJustificativa}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Contrato(s):</Descricao></td>
                                <td>
                                    <tbody>
                                        {contratosLicitacao.map((contrato) => (
                                        <ul key={contrato.aCtrCodigo}>
                                            <li style={{textAlign: 'center'}}><BotaoComoLinkContrato type="button" onClick={ (e) => download(e, contrato.aCtrArqContrato)}>{contrato.aCtrNumero+'/'+contrato.aCtrAno}<FaFilePdf /></BotaoComoLinkContrato></li>
                                        </ul>
                                        ))}
                                    </tbody>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    {licitacaoAnexos.length > 0 ? (
                    <>
                    <h1>Relação de anexos</h1>
                    <TabelaResultado>
                        <thead>
                            <tr>
                                <td style={{textAlign: 'center'}}>Data</td>
                                <td>Tipo</td>
                                <td>Titulo</td>
                                <td style={{textAlign: 'center'}}>Arquivo</td>
                            </tr>
                        </thead>
                        <tbody>
                            {licitacaoAnexos.map((licitacaoAnexo) => (
                                <tr key={licitacaoAnexo.arqId}>
                                    <td>{licitacaoAnexo.lanInicioValidade}</td>
                                    <td>{licitacaoAnexo.tanNome}</td>
                                    <td>{licitacaoAnexo.lanNome}</td>
                                    <td style={{textAlign: 'center'}}><BotaoComoLink type="button" onClick={ (e) => download(e, licitacaoAnexo.lanArquivoNome)}><FaFilePdf /></BotaoComoLink></td>
                                </tr>
                            ))}
                        </tbody>
                    </TabelaResultado>
                    </>
                    ) : (null)}
                    <UltimaAtualizacao>
                    Última Atualização em {aLicUltimaAtualizacao}
                    </UltimaAtualizacao>
                    <CentralizaBotao>
                        <Fechar name="btnFechar" clickHandler={fechaHandler} />
                    </CentralizaBotao>
                    </Scroll>
                </ContainerModal>
            </Modal>
        </>
    );
};

export default ModalLicitacao;
