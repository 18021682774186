/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, ListaGestaoFiscal, TituloExplicacaoSemUnderline, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { Link } from 'react-router-dom';

function LrfGestaoFiscal(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <ul>
                    <ListaGestaoFiscal><Link to="/despesa-pessoal"><span>Demonstrativo da Despesa com Pessoal</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/disponibilidade-caixa"><span>Demonstrativo da Disponibilidade de Caixa</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/restos-a-pagar"><span>Demonstrativo dos Restos a Pagar</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/demostrativo-gestao-fiscal"><span>Demonstrativo Simplificado do Relatório de Gestão Fiscal</span></Link></ListaGestaoFiscal>
                </ul>
                <br />
                <TituloExplicacaoSemUnderline>
                Lei de Responsabilidade Fiscal: <a href="http://www.planalto.gov.br/ccivil_03/leis/LCP/Lcp101.htm" target="_blank" rel="noopener noreferrer"><span>LEI COMPLEMENTAR Nº 101, DE 4 DE MAIO DE 2000</span></a>
                </TituloExplicacaoSemUnderline>
                <UltimaAtualizacao>
                Última Atualização em 22/02/2019 11:16
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default LrfGestaoFiscal;
