/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function AtasAudienciaPublica(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_28_de_setembro_de_2016_-_2º_Quadrimestre_de_2016_-_AP_Financas.pdf")}><span>28 de setembro de 2016 - 2º Quadrimestre de 2016 - AP Finanças</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_26_de_setembro_de_2016_-_2º_Quadrimestre_-_AP_Saude.pdf")}><span>26 de setembro de 2016 - 2º Quadrimestre - AP Saúde</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_8_de_junho_de_2016_-_AP_PLDO_2017.pdf")}><span>8 de junho de 2016 - AP PLDO 2017</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_31_de_maio_de_2016_-_1º_Quadrimestre_de_2016_-_AP_Financas.pdf")}><span>31 de maio de 2016 - 1º Quadrimestre de 2016 - AP Finanças</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_25_de_maio_de_2016_-_1º_Quadrimestre_de_2016_-_AP_Saude.pdf")}><span>25 de maio de 2016 - 1º Quadrimestre de 2016 - AP Saúde</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_29_de_fevereiro_de_2016_-_3º_Quadrimestre_de_2015_-_AP_Financas.pdf")}><span>29 de fevereiro de 2016 - 3º Quadrimestre de 2015 - AP Finanças</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_24_de_fevereiro_de_2016_-_3º_Quadrimestre_de_2015_-_AP_Saude.pdf")}><span>24 de fevereiro de 2016 - 3º Quadrimestre de 2015 - AP Saúde</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_Comissao_Economia_Financas_e_Fiscalizacao_LDO.pdf")}><span>Comissao_Economia_Financas_e_Fiscalizacao_LDO</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_Audiencia_Saude_3º_Quadrimestre_2014.pdf")}><span>Audiencia_Saude_3º_Quadrimestre_2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_Metas_Fiscais_3º_Quadrimestre_2014.pdf")}><span>Metas_Fiscais_3º_Quadrimestre_2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_Saude_1º_Quadrimestre_2015.pdf")}><span>Saude_1º_Quadrimestre_2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "atas_Comissao_de_Economia_Financas_e_Fiscaliza2015.pdf")}><span>Comissao_de_Economia_Financas_e_Fiscaliza2015</span></BotaoComoLink><FaFilePdf /></ItemLista>

                </ul>
                ) : (null)}
                <UltimaAtualizacao>
                Última Atualização em 13/02/2020 15:40
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default AtasAudienciaPublica;
