/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, ItemLista, TituloLista } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function PortariasEAtos(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/><Link to="/portarias-2018">Portarias até 2018</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/atos-mesa-2018">Atos da Mesa até 2018</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/atos-2018">Atos de exoneração e nomeação até 2018</Link></TituloLista>
                <br />
                <TituloLista>Diário Oficial do Município</TituloLista>
                <ul>
                    <ItemLista><a href="http://legisladocexterno.curitiba.pr.gov.br/diarioconsultaexterna_pesquisa.aspx" target="_blank" rel="noopener noreferrer"><span>Publicação Atos Câmara de Curitiba - Formato jornal</span></a></ItemLista>
                    <ItemLista><a href="http://legisladocexterno.curitiba.pr.gov.br/AtosConsultaExterna.aspx" target="_blank" rel="noopener noreferrer"><span>Publicação Atos Câmara de Curitiba - Consulta com critérios específicos</span></a></ItemLista>
                </ul>
            </Container>
        </DefaultLayout>
    );
}

export default PortariasEAtos;
