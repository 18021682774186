/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function PortariasAte2018(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2018');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2018'} onChange={alteraAno}>
                        <option key='2018' value='2018'>
                            2018
                        </option>
                        <option key='2017' value='2017'>
                            2017
                        </option>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                        <option key='2013' value='2013'>
                            2013
                        </option>
                        <option key='2012' value='2012'>
                            2012
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2018' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Outubro_2018_(545_a_585)pdf.pdf")}><span>Portarias Outubro/2018 (545 a 585)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Setembro_2018_(499_a_544)pdf.pdf")}><span>Portarias Setembro/2018 (499 a 544)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2018_(429_a_498)pdf.pdf")}><span>Portarias Agosto/2018 (429 a 498)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2018_(380_a_428)pdf.pdf")}><span>Portarias Julho/2018 (380 a 428)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho_2018_(325_a_379)_pdf.pdf")}><span>Portarias Junho/2018 (325 a 379)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio_2018_(243_a_324)pdf.pdf")}><span>Portarias Maio/2018 (243 a 324)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2018_(190_a_242)pdf.pdf")}><span>Portarias Abril/2018 (190 a 242)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2018_(128_a_189)pdf.pdf")}><span>Portarias Março/2018 (128 a 189)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2018_(69_a_127)pdf.pdf")}><span>Portarias Fevereiro/2018 (69 a 127)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Janeiro_2018_(1_a_68)pdf.pdf")}><span>Portarias Janeiro/2018 (1 a 68)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2017' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Dezembro_2017_(558_a_637)pdf.pdf")}><span>Portarias Dezembro/2017 (558 a 637)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Novembro_2017_(487_a_557)pdf.pdf")}><span>Portarias Novembro/2017 (487 a 557)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Outubro_2017_(450_a_486)pdf.pdf")}><span>Portarias Outubro/2017 (450 a 486)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Setembro_2017_(387_a_449)pdf.pdf")}><span>Portarias Setembro/2017 (387 a 449)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2017_(335_a_386)pdf.pdf")}><span>Portarias Agosto/2017 (335 a 386)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Novembro_2017_(487_a_493)pdf.pdf")}><span>Portarias Novembro/2017 (487 a 493)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2017_(284_a_334)pdf.pdf")}><span>Portarias Julho/2017 (284 a 334)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho_2017_(227_a_283)pdf.pdf")}><span>Portarias Junho/2017 (227 a 283)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio__2017_(173_a_226)pdf.pdf")}><span>Portarias Maio /2017 (173 a 226)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2017_(132_a_172_+_117)pdf.pdf")}><span>Portarias Abril/2017 (132 a 172 + 117)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2017_(78_a_131_e_698-R_-41R).pdf")}><span>Portarias Março/2017 (78 a 131 e 698-R -41R)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2017_(45_a_77).pdf")}><span>Portarias Fevereiro/2017 (45 a 77)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_janeiro_2017_(01_a_44)_pdf.pdf")}><span>Portarias janeiro/2017 (01 a 44)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_dezembro_2016_(651_a_706)pdf.pdf")}><span>Portarias dezembro/2016 (651 a 706)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Novembro_2016_(605_a_650)pdf.pdf")}><span>Portarias Novembro/2016 (605 a 650)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Outubro__2016_(558_a_604)pdf.pdf")}><span>Portarias Outubro /2016 (558 a 604)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Setembro__2016_(516_a_557)pdf.pdf")}><span>Portarias Setembro /2016 (516 a 557)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2016(452_a_515)pdf.pdf")}><span>Portarias Agosto/2016(452 a 515)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2016(386_a_451)Resolu_C_eo_nÂO_4pdf.pdf")}><span>Portarias Julho/2016(386 a 451)Resolução nº 4</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Edital_de_Convoca_C_eo_01pdf.pdf")}><span>Portaria Presidente - Edital de Convocação 01</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho__2016_(290_a_385)_pdf.pdf")}><span>Portarias Junho /2016 (290 a 385)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio__2016_(236_a_289)pdf.pdf")}><span>Portarias Maio /2016 (236 a 289)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2016_(184_a_235)pdf.pdf")}><span>Portarias Abril/2016 (184 a 235)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2016_(118_a_183)pdf.pdf")}><span>Portarias Março/2016 (118 a 183)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2016_(71_a_117)pdf.pdf")}><span>Portarias Fevereiro/2016 (71 a 117)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Janeiro_(01_a_70)_+_Republica_C_Iespdf.pdf")}><span>Portaria Janeiro (01 a 70) + Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria__Dezembro_(624_a_666)pdf.pdf")}><span>Portaria Dezembro (624 a 666)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria__Novembro_(578_a_623)pdf.pdf")}><span>Portaria Novembro (578 a 623)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Outubro_(528_a_577)pdf.pdf")}><span>Portaria Outubro (528 a 577)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Setembro(479_a_527)pdf.pdf")}><span>Portaria Setembro(479 a 527)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2015_(427_a_478)pdf.pdf")}><span>Portarias Agosto/2015 (427 a 478)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2015_(371_a_426)pdf.pdf")}><span>Portarias Julho/2015 (371 a 426)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho_2015_(302_a_370)pdf.pdf")}><span>Portarias Junho/2015 (302 a 370)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio_2015_(244_a_301)pdf.pdf")}><span>Portarias Maio/2015 (244 a 301)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2015_(196_a_243)+Republicapdf.pdf")}><span>Portarias Abril/2015 (196 a 243)+Republica</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2015_(132_a_195)+Republicapdf.pdf")}><span>Portarias Março/2015 (132 a 195)+Republica</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2015_(71_a_131)+Republicapdf.pdf")}><span>Portarias Fevereiro/2015 (71 a 131)+Republica</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Janeiro_2015_(01_a_70)pdf.pdf")}><span>Portarias Janeiro/2015 (01 a 70)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Dezembro_2014_(662_a_722)+Republicapdf.pdf")}><span>Portarias Dezembro/2014 (662 a 722)+Republica</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Novembro_2014_(585_a_661)+Republicapdf.pdf")}><span>Portarias Novembro/2014 (585 a 661)+Republica</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Outubro_2014_(523_a_584)pdf.pdf")}><span>Portarias Outubro/2014 (523 a 584)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Setembro_2014_(487_a_522)pdf.pdf")}><span>Portarias Setembro/2014 (487 a 522)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2014(433_a_486)pdf.pdf")}><span>Portarias Agosto/2014(433 a 486)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2014(366_a_432)+Republica_C_Iespdf.pdf")}><span>Portarias Julho/2014(366 a 432)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho_2014_(310_a_365)+Republica_C_eopdf.pdf")}><span>Portarias Junho/2014 (310 a 365)+Republicação</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio_2014_(237_a_309)+Republica_C_eo_pdf.pdf")}><span>Portarias Maio/2014 (237 a 309)+Republicação</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2014_(167_a_243)+Republica_C_Iespdf.pdf")}><span>Portarias Abril/2014 (167 a 243)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2014_(117_a_166)pdf.pdf")}><span>Portarias Março/2014 (117 a 166)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2014_(71_a_116)+Republica_C_Iespdf.pdf")}><span>Portarias Fevereiro/2014 (71 a 116)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Janeiro_2014_(1_a_70)pdf.pdf")}><span>Portarias Janeiro/2014 (1 a 70)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2013' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Dezembro_2013_(488_a_539)+Republica_C_eopdf.pdf")}><span>Portarias Dezembro/2013 (488 a 539)+Republicação</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Novembro_2013_(441_a_487)+Republica_C_Iespdf.pdf")}><span>Portarias Novembro/2013 (441 a 487)+Republicações</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Outubro_2013_(393_a_440)pdf.pdf")}><span>Portarias Outubro/2013 (393 a 440)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Setembro_2013_(341_a_392)pdf.pdf")}><span>Portarias Setembro/2013 (341 a 392)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Agosto_2013_(288_a_340)pdf.pdf")}><span>Portarias Agosto/2013 (288 a 340)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Julho_2013_(239_a_287)pdf.pdf")}><span>Portarias Julho/2013 (239 a 287)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Junho_2013_(191_a_238)pdf.pdf")}><span>Portarias Junho/2013 (191 a 238)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Maio_2013_(139_a_190)pdf.pdf")}><span>Portarias Maio/2013 (139 a 190)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Abril_2013_(104_a_138)pdf.pdf")}><span>Portarias Abril/2013 (104 a 138)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Mar_Co_2013_(57_a_103)pdf.pdf")}><span>Portarias Março/2013 (57 a 103)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Fevereiro_2013_(42_a_56)pdf.pdf")}><span>Portarias Fevereiro/2013 (42 a 56)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Janeiro_2013_(01_a_41)pdf.pdf")}><span>Portarias Janeiro/2013 (01 a 41)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_(280_-_306_2012)_pdf.pdf")}><span>Portaria Diretor Geral (280 - 306/2012)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Com._Exec.pdf")}><span>Portaria Com. Exec. - Dezembro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2012' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_(262_-_279_2012)pdf.pdf")}><span>Portaria Diretor Geral (262 - 279/2012)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Com._Exec(1).pdf")}><span>Portaria Com. Exec. - Novembro/2012 (29 - 32)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_(247_a_261_2012)pdf.pdf")}><span>Portaria Diretor Geral (247 a 261/2012)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Outubro_2012_(061-071)pdf.pdf")}><span>Portaria Presidente Outubro/2012 (061-071)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Outubro_2012_(243-246)pdf.pdf")}><span>Portaria Diretor Geral Outubro/2012 (243-246)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Com._Exec(2).pdf")}><span>Portaria Com. Exec. - Outubro/2012 (27 e 28)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Setembro_2012pdf.pdf")}><span>Portaria Presidente Setembro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Setembro_2012_IIpdf.pdf")}><span>Portaria Diretor Geral Setembro/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Com.pdf")}><span>Portaria Com. Exec - Setembro/2012 II (24-26)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Setembro_2012pdf.pdf")}><span>Portaria Diretor Geral Setembro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Com._Exec.pdf")}><span>Portaria Com. Exec. - Setembro/2012 (23)</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Agosto_2012pdf.pdf")}><span>Portaria Presidente Agosto/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Agosto_2012_IIpdf.pdf")}><span>Portaria Diretor Geral Agosto/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Agosto_2012_Ipdf.pdf")}><span>Portaria Diretor Geral Agosto/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_Agosto_2012pdf.pdf")}><span>Portaria Comissão Executiva - Agosto/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Julho_2012_pdf.pdf")}><span>Portaria Presidente Julho/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_Julho_2012_IIpdf.pdf")}><span>Portaria Comissão Executiva - Julho/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Julho_2012pdf.pdf")}><span>Portaria Presidente Julho/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Julho_2012_IIIpdf.pdf")}><span>Portaria Diretor Geral Julho/2012 III</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Julho_2012_IIpdf.pdf")}><span>Portaria Diretor Geral Julho/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Julho_2012_Ipdf.pdf")}><span>Portaria Diretor Geral Julho/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_Julho_2012_Ipdf.pdf")}><span>Portaria Comissão Executiva - Julho/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Junho_2012_IVpdf.pdf")}><span>Portaria Presidente Junho/2012 IV</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Junho_2012_IVpdf.pdf")}><span>Portaria Diretor Geral Junho/2012 IV</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_Junho_2012_IIIpdf.pdf")}><span>Portaria Presidente Junho/2012 III</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_Junho_2012_IIIpdf.pdf")}><span>Portaria Diretor Geral Junho/2012 III</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_junho_2012_IIpdf.pdf")}><span>Portaria Presidente junho/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_junho_2012_IIpdf.pdf")}><span>Portaria Diretor Geral - junho/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_junho_2012_Ipdf.pdf")}><span>Portaria Presidente junho/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_junho_2012_Ipdf.pdf")}><span>Portaria Diretor Geral - junho/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Maio_2012pdf.pdf")}><span>Portaria Presidente - Maio/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Maio_II_2012_pdf.pdf")}><span>Portaria Diretor Geral - Maio II/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_Maio_II_2012pdf.pdf")}><span>Portaria Comissão Executiva - Maio II/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Maio_I_2012_pdf.pdf")}><span>Portaria Diretor Geral - Maio I/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_Maio_I_2012pdf.pdf")}><span>Portaria Comissão Executiva - Maio I/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Comunicado_Comiss_eo_Executiva_-_abril_2012_pdf.pdf")}><span>Comunicado Comissão Executiva - abril/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Abril_2012_IIIpdf.pdf")}><span>Portaria Diretor Geral - Abril/2012 III</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_III_-_abril_2012pdf.pdf")}><span>Portaria Comissão Executiva III - abril/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Abril_2012_IIpdf.pdf")}><span>Portaria Presidente - Abril/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Abril_2012_IIpdf.pdf")}><span>Portaria Diretor Geral - Abril/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_II_-_abril_2012pdf.pdf")}><span>Portaria Comissão Executiva II - abril/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Abril_2012_Ipdf.pdf")}><span>Portaria Presidente - Abril/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Abril_2012_Ipdf.pdf")}><span>Portaria Diretor Geral - Abril/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_I_-_abril_2012pdf.pdf")}><span>Portaria Comissão Executiva I - abril/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Mar_2012__IIpdf.pdf")}><span>Portaria Presidente - Mar/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Mar_2012_IIpdf.pdf")}><span>Portaria Diretor Geral - Mar/2012 II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Diretor_Geral_-_Mar_2012_Ipdf.pdf")}><span>Portarias Diretor Geral - Mar/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Mar_2012__Ipdf.pdf")}><span>Portaria Presidente - Mar/2012 I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_mar_Co_2012pdf.pdf")}><span>Portaria Comissão Executiva - março/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Diretor_Geral_-_Fev_2012_-_IIpdf.pdf")}><span>Portarias Diretor Geral - Fev/2012 - II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Fev_2012_-_IIpdf.pdf")}><span>Portaria Presidente - Fev/2012 - II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Diretor_Geral_-_Fev_2012_-_Ipdf.pdf")}><span>Portarias Diretor Geral - Fev/2012 - I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Fev_2012_-_Ipdf.pdf")}><span>Portaria Presidente - Fev/2012 - I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Jan_2012_-_IIpdf.pdf")}><span>Portaria Presidente - Jan/2012 - II</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Jan-Fev_2012pdf.pdf")}><span>Portaria Diretor Geral - Jan-Fev/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_fevereiro_2012pdf.pdf")}><span>Portaria Comissão Executiva - fevereiro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_-_Jan_2012_-_I_pdf.pdf")}><span>Portaria Presidente - Jan/2012 - I</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Diretor_Geral_-_Janeiro_2012pdf.pdf")}><span>Portaria Diretor Geral - Janeiro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Comiss_eo_Executiva_-_janeiro_2012pdf.pdf")}><span>Portaria Comissão Executiva - janeiro/2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portarias_Diretor_Geral_-_dez_2011pdf.pdf")}><span>Portarias Diretor Geral - dez 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Portaria_Presidente_dez_2011pdf.pdf")}><span>Portaria Presidente dez/2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <UltimaAtualizacao>
                Última Atualização em 26/10/2018 14:34
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default PortariasAte2018;
