/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, ListaGestaoFiscal, TituloExplicacaoSemUnderline, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { Link } from 'react-router-dom';

function AnexosLei4320(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <ul>
                    <ListaGestaoFiscal><Link to="/receita-despesa-categorias"><span>Demostrativo da Receita e Despesa segundo as categorias econômicas (Anexo 1, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/despesa-categorias-economicas"><span>Despesa segundo as categorias econômicas (Anexo 2, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/despesa-programa-trabalho"><span>Despesa por Programa de Trabalho (Anexo 6, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/comparativo-despesa-autorizada"><span>Comparativo da despesa autorizada com a realizada (Anexo 11, da Lei 4320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/balanco-financeiro"><span>Balanço Financeiro (Anexo 13, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/balanco-patrimonial"><span>Balanço Patrimonial (Anexo 14, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/demonstrativo-variacao-patrimonial"><span>Demonstrativo das Variações Patrimoniais (Anexo 15, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                    <ListaGestaoFiscal><Link to="/demonstrativo-fluxo-caixa"><span>Demonstrativo dos Fluxos de Caixa (Anexo 18, da Lei nº 4.320/64)</span></Link></ListaGestaoFiscal>
                </ul>
                <br />
                <TituloExplicacaoSemUnderline>
                Lei Federal 4.320/64: <a href="http://www.planalto.gov.br/ccivil_03/Leis/L4320.htm" target="_blank" rel="noopener noreferrer"><span>LEI Nº 4.320, DE 17 DE MARÇO DE 1964</span></a>
                </TituloExplicacaoSemUnderline>
                <UltimaAtualizacao>
                Última Atualização em 09/04/2019 14:20
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default AnexosLei4320;
