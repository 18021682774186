/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { Texto, Titulo, UltimaAtualizacao } from '../Glossario/styles';

function Legislacao(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <Titulo>Legislação</Titulo>
                <Texto>
                Para acessar os textos atualizados das normas em vigor na cidade de Curitiba, busque pelo código da lei ou pelo assunto em <a href="https://leismunicipais.com.br/legislacao-municipal/5520/leis-de-curitiba" target="_blank" rel="noopener noreferrer">Leis Municipais Consolidadas</a>.
                Se o objetivo é encontrar o texto original das normas utilize o <a href="http://www.cmc.pr.gov.br/wspl/sistema/BibLegislacaoForm.jsp" target="_blank" rel="noopener noreferrer">Sistema de Proposições Legislativas (SPL)</a>.
                O sistema dá acesso ainda a outras informações da Câmara, como projetos de lei em tramitação, pedidos de informação ao Executivo, requerimentos para os bairros,
                ordem do dia das sessões plenárias, entre outras.
                </Texto>
                <UltimaAtualizacao>
                Última Atualização em 17/08/2015 08:00
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default Legislacao;
