import React, { useCallback, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import Modal from 'react-modal';
import api from 'src/service/api';
import { download } from 'src/utils/downloadArquivo';
import ModalDetalhes from 'src/components/ModalLicitacao';
import Fechar from '../layout/button/Fechar';
import { ContainerModal, Descricao, Scroll, BotaoComoLink, CentralizaBotao, TabelaAditivo } from './styles';

interface IModalContrato {
    fechaModalContrato?: any;
    modalContrato?: any;
    id?: any;
}

const ModalContrato: React.FC<IModalContrato> = ({ fechaModalContrato, modalContrato, id }) => {

    const [aCtrNumero, setACtrNumero] = useState('');
    const [aCtrData, setACtrData] = useState('');
    const [aCtrContratado, setACtrContratado] = useState('');
    const [aCtrObjeto, setACtrObjeto] = useState('');
    const [aCtrPrecoInicial, setACtrPrecoInicial] = useState('');
    const [aCtrPrecoFinal, setACtrPrecoFinal] = useState('');
    const [aCtrDuracao, setACtrDuracao] = useState('');
    const [aCtrValorAtualizado, setACtrValorAtualizado] = useState('');
    const [aCtrTermino, setACtrTermino] = useState('');
    const [aCtrArqContrato, setACtrArqContrato] = useState('');
    const [aCtrAno, setACtrAno] = useState('');
    const [aCtrDataDom, setACtrDataDom] = useState('');

    const [modalDetalhes, setModalDetalhes] = useState(false);
    const [idModalDetalhes, setIdModalDetalhes] = useState(0);

    const [licitacaoContrato, setLicitacaoContrato] = useState('');
    const [idLicitacaoContrato, setIdLicitacaoContrato] = useState('');

    const [alteracoesContratuais, setAlteracoesContratuais] = useState([]);

    function fechaHandler(e: any): any {
            fechaModalContrato(e.target.value);
    }

    const carregaContrato = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/contrato/${id}`);
            console.log(response.data);
            setACtrNumero(response.data.aCtrNumero);
            setACtrData(response.data.aCtrData);
            setACtrContratado(response.data.aCtrContratado);
            setACtrObjeto(response.data.aCtrObjeto);
            setACtrPrecoInicial(response.data.aCtrPrecoInicial);
            setACtrPrecoFinal(response.data.aCtrPrecoFinal);
            setACtrDuracao(response.data.aCtrDuracao);
            setACtrValorAtualizado(response.data.aCtrValorAtualizado);
            setACtrTermino(response.data.aCtrTermino);
            setACtrArqContrato(response.data.aCtrArqContrato);
            setACtrAno(response.data.aCtrAno);
            setACtrDataDom(response.data.aCtrDataDom);
        } catch (err) {
            console.log(err);
        }
    }, []);

    function verDetalhes(id: any): void {
        setIdModalDetalhes(id);
        setModalDetalhes(true);
    }

    const carregaLicitacaoContrato = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/licitacao-contrato/${id}`);
            console.log(response.data);
            setLicitacaoContrato(response.data.descricaoLicitacao);
            setIdLicitacaoContrato(response.data.aLicCodigo);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const carregaAditivosContrato = useCallback(async (id: number) => {
        try {
            const response = await api.get(`/aditivos-contrato/${id}`);
            console.log(response.data);
            setAlteracoesContratuais(response.data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        async function carrega(): Promise<void> {
            carregaContrato(id);
            carregaLicitacaoContrato(id);
            carregaAditivosContrato(id);
        }
        carrega();
    }, [carregaContrato, carregaLicitacaoContrato, carregaAditivosContrato, id]);

    return (
        <>
            <Modal
                isOpen={modalContrato}
                onRequestClose={fechaHandler}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(216, 222, 233, 0.75)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        border: '1px solid #000',
                        backgroundColor: '#fff',
                        padding: '5px',
                    },
                }}
                ariaHideApp={false}>
                <ContainerModal>
                    <Scroll>
                    <h1>Detalhes do contrato</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td><Descricao>Número:</Descricao></td>
                                <td>{aCtrNumero+'/'+aCtrAno}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Data de autorização de fornecimento:</Descricao></td>
                                <td>{aCtrData}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Data de publicação no DOM:</Descricao></td>
                                <td>{aCtrDataDom}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Duração:</Descricao></td>
                                <td>{aCtrDuracao}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Contratado:</Descricao></td>
                                <td>{aCtrContratado}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Objeto:</Descricao></td>
                                <td style={{textAlign: 'justify'}}>{aCtrObjeto}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Licitação:</Descricao></td>
                                <td><BotaoComoLink type="button" onClick={ () => verDetalhes(idLicitacaoContrato) }>{licitacaoContrato}</BotaoComoLink></td>
                            </tr>
                            <tr>
                                <td><Descricao>Preço inicial estimado:</Descricao></td>
                                <td>{aCtrPrecoInicial}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Preço final:</Descricao></td>
                                <td>{aCtrPrecoFinal}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Valor atualizado:</Descricao></td>
                                <td>{aCtrValorAtualizado}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Término:</Descricao></td>
                                <td>{aCtrTermino}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><BotaoComoLink type="button" onClick={ (e) => download(e, aCtrArqContrato)}>Clique aqui para baixar o contrato <FaFilePdf /></BotaoComoLink></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    {alteracoesContratuais.length > 0 ? (
                    <>
                    <h1>Alterações contratuais</h1>
                    {alteracoesContratuais.map((alteracaoContratual) => (
                    <>
                    <TabelaAditivo key={alteracaoContratual.aAdtCodigo}>
                        <tbody>
                            <tr>
                                <td colSpan={2}><Descricao>{alteracaoContratual.aAdtObjeto.substring(0,alteracaoContratual.aAdtObjeto.indexOf(':'))}</Descricao></td>
                            </tr>
                            <tr>
                                <td><Descricao>Data:</Descricao></td>
                                <td>{alteracaoContratual.aAdtData}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Valor:</Descricao></td>
                                <td>{alteracaoContratual.aAdtValor}</td>
                            </tr>
                            <tr>
                                <td><Descricao>Objeto:</Descricao></td>
                                <td style={{textAlign: 'justify'}}>{alteracaoContratual.aAdtObjeto}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><BotaoComoLink type="button" onClick={ (e) => download(e, alteracaoContratual.aArqNome)}>Clique aqui para baixar a alteração contratual<FaFilePdf /></BotaoComoLink></td>
                            </tr>
                        </tbody>
                    </TabelaAditivo>
                    <br />
                    </>
                    ))}
                    </>
                    ) : (null)}
                    <CentralizaBotao>
                        <Fechar name="btnFechar" clickHandler={fechaHandler} />
                    </CentralizaBotao>
                    </Scroll>
                </ContainerModal>
            </Modal>
            {modalDetalhes && (
                   <ModalDetalhes id={idModalDetalhes.toString()} modalLicitacao fechaModalLicitacao={() => setModalDetalhes(false)}/>
               )}
        </>
    );
};

export default ModalContrato;
