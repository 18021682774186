/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { BotaoComoLink, Container, ItemLista, TituloLista } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight, FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function ReceitasEDespesas(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/>Interferência Financeira (receita): </TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "interferencia-financeira.pdf")}><span>Interferência Financeira.pdf</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Despesas Empenhadas:</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2016.pdf")}><span>Relatório de Despesas Empenhadas - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2015.pdf")}><span>Relatório de Despesas Empenhadas - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2014.pdf")}><span>Relatório de Despesas Empenhadas - 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2013.pdf")}><span>Relatório de Despesas Empenhadas - 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2012.pdf")}><span>Relatório de Despesas Empenhadas - 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2011.pdf")}><span>Relatório de Despesas Empenhadas - 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Empenhadas-2010.pdf")}><span>Relatório de Despesas Empenhadas - 2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Despesas Liquidadas:</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2016.pdf")}><span>Relatório de Despesas Liquidadas - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2015.pdf")}><span>Relatório de Despesas Liquidadas - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2014.pdf")}><span>Relatório de Despesas Liquidadas - 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2013.pdf")}><span>Relatório de Despesas Liquidadas - 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2012.pdf")}><span>Relatório de Despesas Liquidadas - 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2011.pdf")}><span>Relatório de Despesas Liquidadas - 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-despesas-liquidadas-2010.pdf")}><span>Relatório de Despesas Liquidadas - 2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Despesas Pagas:</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2016.pdf")}><span>Relatório de Despesas Pagas - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2015.pdf")}><span>Relatório de Despesas Pagas - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2014.pdf")}><span>Relatório de Despesas Pagas - 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2013.pdf")}><span>Relatório de Despesas Pagas - 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2012.pdf")}><span>Relatório de Despesas Pagas - 2012</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2011.pdf")}><span>Relatório de Despesas Pagas - 2011</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Despesas-Pagas-2010.pdf")}><span>Relatório de Despesas Pagas - 2010</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Notas Extraorçamentárias:</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Notas-Extraordinarias-2016.pdf")}><span>Relatório de Notas Extraorçamentárias - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Relatorio-de-Notas-Extraordinarias-2015.pdf")}><span>Relatório de Notas Extraorçamentárias - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                <TituloLista><FaAngleRight size={28}/>Diárias de Custo:</TituloLista>
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Diarias_2016.pdf")}><span>Relatório de Diárias de Custo - 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Diarias_2015.pdf")}><span>Relatório de Diárias de Custo - 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Diarias_2014.pdf")}><span>Relatório de Diárias de Custo - 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "Diarias_2013.pdf")}><span>Relatório de Diárias de Custo - 2013</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>

            </Container>
        </DefaultLayout>
    );
}

export default ReceitasEDespesas;
