/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { BotaoComoLink, Container, ItemLista, TituloAno, SelectAno, UltimaAtualizacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaFilePdf } from 'react-icons/fa';
import { download } from 'src/utils/downloadArquivo';

function DemonstrativoFluxoCaixa(): React.ReactNode {

    const [anoSelecionado, setAnoSelecionado] = useState<String>('2016');

    const alteraAno = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const ano = event.target.value;
        setAnoSelecionado(ano);
      };

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloAno>Ano:
                    <SelectAno id="ano" defaultValue={'2016'} onChange={alteraAno}>
                        <option key='2016' value='2016'>
                            2016
                        </option>
                        <option key='2015' value='2015'>
                            2015
                        </option>
                        <option key='2014' value='2014'>
                            2014
                        </option>
                    </SelectAno>
                </TituloAno>
                <br />
                {anoSelecionado === '2016' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_12_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Dezembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_11_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Novembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_10_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Outubro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_09_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Setembro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_08_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Agosto 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_07_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Julho 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_06_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Junho 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_05_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Maio 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_04_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Abril 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_03_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Março 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_29_02_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Fevereiro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_01_2016.pdf")}><span>Demonstr. dos Fluxos de Caixa - Janeiro 2016</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2015' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_12_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Dezembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_01_12_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Novembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_10_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Outubro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_09_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Setembro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_08_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Agosto 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_07_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Julho 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_06_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Junho 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_05_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Maio 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_04_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Abril 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_03_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Março 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_28_02_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Fevereiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_01_2015.pdf")}><span>Demonstr. dos Fluxos de Caixa - Janeiro 2015</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                {anoSelecionado === '2014' ? (
                <ul>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_12_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Dezembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_01_12_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Novembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_10_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Outubro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_09_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Setembro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_08_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Agosto 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_07_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Julho 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_06_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Junho 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_05_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Maio 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_30_04_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Abril 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_03_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Março 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_28_02_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Fevereiro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                    <ItemLista><BotaoComoLink type="button" onClick={ (e) => download(e, "DFC_CMC_Contabilidade_31_01_2014.pdf")}><span>Demonstr. dos Fluxos de Caixa - Janeiro 2014</span></BotaoComoLink><FaFilePdf /></ItemLista>
                </ul>
                ) : (null)}
                <UltimaAtualizacao>
                Última Atualização em 19/03/2019 10:29
                </UltimaAtualizacao>
            </Container>
        </DefaultLayout>
    );
}

export default DemonstrativoFluxoCaixa;
