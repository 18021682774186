import React from 'react';

import Select from '../Select';

export interface ISelectSituacao {
    name: string;
    label?: string;
    changeHandler?: any;
    obrigatorio?: any;
    onChange?: any;
    style?: any;
    rest?: any;
    defaultValue?: any;
}

const SelectSituacao: React.FC<ISelectSituacao> = ({ name, changeHandler, label, ...rest }) => {
    const options = [
        {
            label: 'Anulada',
            value: '1',
        },
        {
            label: 'Deserta',
            value: '2',
        },
        {
            label: 'Em andamento',
            value: '3',
        },
        {
            label: 'Em recurso',
            value: '4',
        },
        {
            label: 'Fracassada',
            value: '5',
        },
        {
            label: 'Homologada',
            value: '6',
        },
        {
            label: 'Julgada',
            value: '7',
        },
        {
            label: 'Republicada',
            value: '8',
        },
        {
            label: 'Revogada',
            value: '9',
        },
        {
            label: 'Concluída',
            value: '10',
        },
        {
            label: 'Suspensa',
            value: '11',
        },
    ];

    return (
        <Select name={name} label={label} options={options} onChange={changeHandler} {...rest} />
    );
};

export default SelectSituacao;
