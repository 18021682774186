/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, LinkExterno, Texto, TituloExplicacao } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';

function LdoLoaPpa(): React.ReactNode {

    return (
        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloExplicacao>Nota:</TituloExplicacao>
                <Texto>Estas leis referem-se ao município de Curitba. </Texto>
                <Texto>No link abaixo você será direcionado ao portal da transparência da Prefeitura Municipal de Curitiba.</Texto>
                <LinkExterno href="http://www.orcamentos.curitiba.pr.gov.br/" target="_blank" rel="noopener noreferrer"><span>Leis orçamentárias (LDO,LOA,PPA)</span></LinkExterno>
                <br />
                <br />
                <br />
            </Container>
        </DefaultLayout>
    );
}

export default LdoLoaPpa;
