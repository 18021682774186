/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, TituloLista } from './styles';
import DefaultLayout from '../_layouts/default';
import TituloInterno from 'src/components/TituloInterno';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function OrcamentoEFinancas(): React.ReactNode {

    return (

        <DefaultLayout>
            <TituloInterno />
            <Container>
                <TituloLista><FaAngleRight size={28}/><Link to="/orcamento-legislativo">Orçamento do Legislativo</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/ldo-loa-ppa">LDO, LOA e PPA</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/anexos-4320">Anexos da Lei 4.320/64</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/lrf-resumido">LRF - Relatório resumido da execução orçamentária</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/lrf-completo">LRF - Relatório de gestão fiscal</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/execucao-orcamentaria-desdobramento">Execução orçamentária por desdobramento da despesa</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/diario-bancos">Operações financeiras de qualquer natureza (diário de bancos)</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/fec">Fundo Especial da Câmara Municipal de Curitiba</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/atas-audiencia-publica">Atas das audiências públicas</Link></TituloLista>
                <TituloLista><FaAngleRight size={28}/><Link to="/plano-contas-legislativo">Plano de contas do Legislativo</Link></TituloLista>
            </Container>
        </DefaultLayout>
    );
}

export default OrcamentoEFinancas;
